import React from "react";
import "../collectAddressDetails/collectAddressDetails.css";
import MESSAGE from "helpers/Message";
import counties from "../../helpers/Counties";
import DropdownWithValues from "../formFields/DropDownWithValues";
import CountyType from "../orderFormNhdReport/Models/CountyType";

interface CollectApnDetailsProps {
    defaultApn: string;
    defaultState: string;
    defaultZip: string;
    defaultCounty: CountyType | null;
    onUseApn: any;
    onClose?: Function;
    apnChangeHandler: Function;
}

interface CollectApnDetailsState {
    apn: string;
    state: string;
    zipCode: string;
    county: string;
    isApnRequired: boolean;
    isStateRequired: boolean;
    isZipRequired: boolean;
    isCountyRequired: boolean;
}

class CollectApnDetails extends React.Component<
    CollectApnDetailsProps,
    CollectApnDetailsState
    > {
    constructor(props: CollectApnDetailsProps) {
        super(props);
        this.state = {
            apn: this.props.defaultApn,
            state: this.props.defaultState,
            zipCode: this.props.defaultZip,
            county: this.props.defaultCounty ? this.props.defaultCounty.countyNum.toString() : "",
            isApnRequired: false,
            isStateRequired: false,
            isZipRequired: false,
            isCountyRequired: false
        };
    }

    onChangeHandler(event, formName) {
        const stateObject = this.state;
        stateObject[formName] = event.target.value;
        this.setState(stateObject)
    }

    handleCountyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let option = e.target.selectedOptions.item(0);
        let value = 1;

        if (option != null) {
            value = parseInt(option.value);
        } else if (this.props.defaultCounty) {
            value = this.props.defaultCounty.countyNum;
        }

        this.setState({
            county: value.toString()
        });
    };

    onClose = () => {
        if(this.props.onClose)
            this.props.onClose();
    }

    onSubmit = () => {

        if (this.state.apn && this.state.state) {
            this.props.onUseApn(this.state, this.state.county)
        }

        this.setState({
            isApnRequired: !this.state.apn,
            isStateRequired: !this.state.state,
            isZipRequired: !this.state.zipCode,
            isCountyRequired: this.state.county === "0"
        });
    }

    onClick = () => {
        if(this.props.onClose)
            this.props.onClose();
    }

    render() {
        return (
            <div className="collect-address-apn-details">
                <span onClick={this.onClose} className="close">&times;</span>
                <h4><b>{MESSAGE.update_apn}</b></h4>
                <p className="disclaimer">{MESSAGE.accurate_info_message}</p>
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="collect-street-address" className="label-title req">
                                <b>{MESSAGE.apn_caption}</b>
                            </label>
                            <input
                                type="text"
                                id="collect-apn"
                                name="apn"
                                readOnly={false}
                                placeholder={"Enter Apn"}
                                className={"form-control form-placeholder"}
                                value={this.state.apn}
                                onChange={event => {
                                    this.onChangeHandler(event, "apn")
                                    this.props.apnChangeHandler();
                                }}
                            />
                            {
                                this.state.isApnRequired
                                && (<p className="text-danger">APN is required</p>)
                            }
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="collect-street-address" className="label-title req">
                                <b>{MESSAGE.county}</b>
                            </label>
                            <DropdownWithValues
                                className={
                                    "apn-county-dropdown form-control form-placeholder"
                                }
                                options={counties}
                                required={false}
                                name="propertyCounty"
                                defaultOption={this.props.defaultCounty ? (this.props.defaultCounty.text === "" ? "Select one" : this.props.defaultCounty.text) : ""}
                                handleChange={this.handleCountyChange}
                            />
                            {
                                this.state.isCountyRequired
                                && (<p className="text-danger">County is required</p>)
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="collect-street-address" className="label-title req">
                                <b>State</b>
                            </label>
                            <input
                                type="text"
                                name="state"
                                required={true}
                                readOnly={true}
                                className={"form-control"}
                                value={this.state.state}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="collect-street-address" className="label-title">
                                <b>Zip Code (Optional)</b>
                            </label>
                            <input
                                type="text"
                                name="zipCode"
                                readOnly={false}
                                placeholder={"Enter ZIP Code"}
                                className={"form-control form-placeholder"}
                                value={this.state.zipCode}
                                onChange={event => {
                                    this.onChangeHandler(event, "zipCode")
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <ul className="list-inline text-right">
                        <li className="list-inline-item">
                            <button
                                type="submit"
                                className="btn blue-btn"
                                onClick={this.onSubmit}
                            >
                                {"Continue"}
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

        );
    }
}

export default CollectApnDetails;