import React from "react";

interface TextAreaProps {
  className: string;
  name: string;
  label: string;
  placeholder: string;
  readOnly?: boolean;
  rows: number;
  required: boolean;
  value?: string;
  defaultValue?: string;
  handleBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  handleChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  maxLength?: number;
}

const TextArea: React.FC<TextAreaProps> = ({
  className,
  value,
  name,
  placeholder,
  rows,
  readOnly,
  label,
  required,
  handleBlur,
  handleChange,
  defaultValue,
  maxLength,
}) => {
  return (
    <>
      {label && (
        <label
          htmlFor={name}
          className={"font-weight-bold " + (required ? "req" : "")}
        >
          {label}
        </label>
      )}
      <textarea
        className={className}
        rows={rows}
        placeholder={placeholder}
        id={name}
        name={name}
        required={required}
        readOnly={readOnly}
        onBlur={handleBlur}
        onChange={handleChange}
        defaultValue={defaultValue}
        maxLength={maxLength}
      >
        {value}
      </textarea>
    </>
  );
};

export default TextArea;
