import React, { useEffect, useState } from "react";
import { Input, RadioGroup, TableColumn, Text, Table, Message, Action } from '@ftdr/blueprint-components-react';
import './OrderHistory.css';
import ActionsDropdown from './ActionsDropdown';

const   OrderHistoryInfo = (props) => {

    const actionItems = () => {
        const items: { label: string, onClick: () => void, disabled?: boolean, id?: string }[] = [
            {
                label: 'View Order Details',
                onClick: () => console.log('context menu item 1'),
                disabled: false, 
                id: 'view_order'
            },
            {
                label: 'Delete Order',
                onClick: () => console.log('context menu item 2'),
                disabled: false, 
                id: 'delete_order'
            },
        ];

        return items.map(i => ({ label: i.label, onClick: i.onClick, id: i.id }));
    };

    const columns = [
        {
            Header: <Text variant="heading-06">Order ID</Text>,
            accessor: "order_id",
            Cell: ({ value }) => <Text variant="caption">{value}</Text>
        },
        {
            Header: <Text variant="heading-06">Property Address</Text>,
            accessor: "property_address",
            Cell: ({ value }) => <Text variant="caption">{value}</Text>
        },
        {
            Header: <Text variant="heading-06">APN</Text>,
            accessor: "apn",
            Cell: ({ value }) => <Text variant="caption">{value?? ""}</Text>
        },
        {
            Header: <Text variant="heading-06">Report Type</Text>,
            accessor: "report_type",
            Cell: ({ value }) => <Text variant="caption">{value}</Text>
        },
        {
            Header: <Text variant="heading-06">Ordered</Text>,
            accessor: "order_date",
            Cell: ({ value }) => <Text variant="caption">{value}</Text>
        },
        {
            Header: <Text variant="heading-06">Order Status</Text>,
            accessor: "status",
            Cell: ({ value }) => <Text variant="caption">{value}</Text>
        },
        {
            Header: <Text variant="heading-06">Report Status</Text>,
            accessor: "report_status",
            Cell: ({ value }) => <Text variant="caption">{value}</Text>
        },
        {
            Header: "Escrow",
            accessor: "isEscrow",
            Cell: ({ value }) => <Text variant="caption">{value}</Text>
        },
        // {
        //     Header: "",
        //     id: "actions",
        //     accessor: "order_id",
        //     Cell: ({ value }) => <ActionsDropdown  menuItems={actionItems()} />,
        //     disableSortBy: true,
        // },
    ]

    return (
        <Table
            id="dvOrderHistoryInfo"
            {...{ data: props.data, columns }}
            striped='gray'
            variant="heavy"
            sortable={true}
            selectable={false}
            paginate={true}
            key="order_history_info"
        />
    )
}

export default OrderHistoryInfo;