import React from "react";
import IframeResizer from 'iframe-resizer-react';
import ConfigHelper from "helpers/ConfigHelper";


const PrivacyPolicy = () =>
{
    let privacyPolicyUrl = `${ConfigHelper.ZestyBaseUrl}/privacy_policy/`;
    return <IframeResizer
                src={privacyPolicyUrl}
                title={"Privacy Policy"}
                className={'zesty-iframe'}
                heightCalculationMethod={'lowestElement'}
            />
}
   

export default PrivacyPolicy;