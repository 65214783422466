import React, { Fragment, useEffect, useState, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";
import { getLoginURL, getRegisterURL, logout } from "api/services/authService";
import { TrackGAEvent } from "../gaTracking/GATracking";
import HeaderTabs from "./HeaderTabs";
import HeaderTab from "./HeaderTab";
import HeaderTabType from "../../enums/HeaderTabType"
import HeaderImg from "./HeaderImg";
import PromotionalHeaderBanner from "../PromotionalBanner/PromotionalHeaderBanner";
import "../PromotionalBanner/PromotionalBanner.css";
import ConfigHelper from "helpers/ConfigHelper";
import { PATHS } from 'helpers/Constants'
import { AuthService } from 'api';
import { Features, IsFeatureEnabled } from "core/featureToggle";

interface HeaderProps {
  nhd_logo: string;
  loggedInUser: string;
  login: string;
  sign_up: string;
  is_logged_in: boolean;
  already_have_account: string;
}

const loginClickHandler = (e: any) => {
  TrackGAEvent("link", "click", "login link");
};

const signUpClickHandler = (e: any) => {
  TrackGAEvent("link", "click", "sign up link");
};


const Header: React.FC<HeaderProps> = ({
  nhd_logo,
  loggedInUser,
  login,
  sign_up,
  is_logged_in,
  already_have_account
}) => {
  let nav_items;


  if (is_logged_in) {
    nav_items = (
      <Fragment>
        <li className="nav-item">Welcome, {loggedInUser}</li>
        <li className="nav-item">
          <button
            className="logout-link bg-transparent border-0 text-blue-tertiary mt-2 ml-2 font-weight-bold"
            onClick={logout}>
            Logout
          </button>
        </li>
      </Fragment>
    );
  } else {
    nav_items = (
      <Fragment>
        <li className="nav-item">
          {already_have_account}{" "}
          <a className="login-link" href={getLoginURL()} onClick={loginClickHandler}  >
            {login}
          </a>
        </li>
        <li className="nav-item">
          <a className="btn signUp-link" href={getRegisterURL()} onClick={signUpClickHandler}>
            {sign_up}
          </a>
        </li>
      </Fragment>
    );
  }

  return (
    <div className="pre-bds">
      <div className="header-container">
        <nav className="navbar navbar-expand-lg p-0 header">
          <div className="container-fluid">
            <Link to={ConfigHelper.GetBasePath()}>
              <HeaderImg desc={nhd_logo} />
            </Link>

            <HeaderTabs>
              <HeaderTab
                href={ConfigHelper.GetBasePath() + "/"}
                headerTabType={HeaderTabType.Home}
              />
              <HeaderTab
                href={ConfigHelper.GetBasePath() + PATHS.Resources}
                headerTabType={HeaderTabType.Resources}
              />
              {AuthService.isLoggedIn() && <>
                {IsFeatureEnabled(Features.OrderHistory) && <HeaderTab
                  href={ConfigHelper.GetBasePath() + PATHS.OrderHistory}
                  headerTabType={HeaderTabType.OrderHistory}
                />
                }
                {
                  IsFeatureEnabled(Features.Dashboard) && <HeaderTab
                    href={ConfigHelper.GetBasePath() + PATHS.Dashboard}
                    headerTabType={HeaderTabType.Admin}
                  />
                }
              </>
              }
            </HeaderTabs>
            <ul className="navbar-nav">{nav_items}</ul>
          </div>
        </nav>
        <div className="promotional_banner_header-text">
          <PromotionalHeaderBanner />
        </div>
      </div>
    </div>

  );
};

export default Header;
