import React from "react";
import "./HeaderTabs.css";

interface HeaderTabsProps {
    children: React.ReactNode;
}

const HeaderTabs = (props: HeaderTabsProps) =>
    <ul className={"header-tabs"}>
        {props.children}
    </ul>

export default HeaderTabs;