import React from "react";
import {Button} from "components";
import "../confirmAddress/confirmAddress.css";

export interface UnknownApnModalContentProps {
    apnFromBackend: String,
    apnFromInput: String;
    onEditApn: any;
    onUseApn: any;
}

const ConfirmApn: React.FC<UnknownApnModalContentProps> = ({
                                                               apnFromBackend,
                                                               apnFromInput,
                                                               onEditApn,
                                                               onUseApn
                                                           }) => {

    return (
        <div className="typo-address-confirmation-modal-content">
            <div className="styled-text-root type-headline-4 align-center color-black">
                Please Confirm Your Apn
            </div>
            <p className="styled-text-root type-paragraph align-left color-black bold-true address-headline">
                We Found:</p>
            <hr className="horizontal-divider"/>
            <div className="address-candidate">
                <div className="address-text">
                    {apnFromBackend}
                </div>
                <Button buttonText={"Use"} type="primary" onClick={() => onUseApn(apnFromBackend)}
                        className="primary-action-button"/>
            </div>
            <hr className="horizontal-divider"/>
            <p className="styled-text-root type-paragraph align-left color-black bold-true address-headline">
                The Apn You Entered:</p>
            <hr className="horizontal-divider"/>
            <div className="address-candidate">
                <div className="address-text">
                    {apnFromInput}
                </div>
                <Button
                    buttonText={"Use"}
                    type="secondary"
                    onClick={() => onUseApn(apnFromInput)}
                    className="btnConfirm secondary color-undefined medium action-button"
                />
            </div>
            <hr className="horizontal-divider"/>
        </div>
    );
};

export default ConfirmApn;
