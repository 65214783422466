const MESSAGE = {
  nhd_logo: "NHD Logo",
  loggedin_user: "Welcome",
  login: "Login",
  sign_up: "Sign Up",
  banner_title: "Delivering Peace of Mind",
  banner_info: "Natural Hazard Disclosure Reports Delivered to Your Email",
  order_report_card_title: "Order Natural Hazard Report",
  use_address: "Use Address",
  use_apn: "Use APN",
  search_by_property_address: "Search by street address…",
  search_by_property_apn: "Search by APN…",
  continue_button_text: "Continue",
  about_title: "About Natural Hazard Disclosure",
  about_info:
    "The Natural Hazards Disclosure Act, under Sec. 1103 of the California Civil Code, states that real estate seller and brokers are legally required to disclose if the property being sold lies within one or more state or locally mapped hazard areas.  We use superior parcel polygon technology for all our hazard disclosure determinations to ensure the most accurate information for your clients.  With the new, exclusive Know Your Neighborhood™ Report, your clients can see what schools, parks, libraries, and more are near their new home — information that can only be found in our reports.",
  report_title: "American Home Shield NHD Reports & Prices",
  report_heading_one: "NHD Tax & Environmental Report ",
  report_price_one: "$99.95",
  report_heading_two: "NHD Tax Report ",
  report_price_two: "$79.95",

  bundle_text: "Ultimate Bundle Package: ANY",
  bundle_additional_text : " NHD report ordered with",
  bundle_price: "$25",
  bundle_coverage_text: "buyer/seller home warranty coverage* ",  
  get_more_info: "Get more information",
  bundle_details1: "*Receive ",
  bundle_details2: " NHD report for $25 at closing when bundled with",
  bundle_details3: " home warranty plan. Home warranty coverage is an additional cost and will be billed separately.  The NHD Tax & Environmental report will be ordered unless otherwise specified in the delivery message.",

  view_sample: "View Sample",
  real_estate_agent: "Real Estate Agent",
  saved_agents: "Saved Agents",
  saved_agents_option: [
    { name: "Louis Daniels", value: "Real Estate Solutions Colorado" }
  ],
  agent_name: "Agent Name",
  enter_first_name: "Enter first name...",
  enter_last_name: "Enter last name...",
  enter_company_name: "Enter company name...",
  agent_company: "Agent Company",
  enter_phone_number: "Enter phone number...",
  agent_phone_number: "Agent Phone Number",
  enter_email_address: "Enter email address...",
  sale_by_owner: "Select here if this is a For Sale By Owner transaction",
  agent_email: "Agent Email",
  save_agents_for_future_order: "+ Save agents for future order",
  seller_information: "Seller information",
  enter_full_name: "Enter full Name...",
  seller_name: "Seller Name",
  seller_phone: "Seller Phone",
  seller_email: "Seller Email",
  escrow_information: "Escrow Information",
  do_you_have_escrow_information: "Do you have Escrow information?",
  escrow_details: "Escrow details",
  saved_officers: "Saved officers",
  escrow_officer: "Escrow Officer",
  escrow_officer_email: "Escrow Officer Email",
  enter_address: "Enter Street Address...",
  escrow_officer_number: "Escrow Officer Phone Number",
  escrow_company_name: "Escrow Company Name",
  escrow_company_address: "Escrow Company Address",
  enter_street_address: "Enter Street address",
  state: "State",
  enter_state: "Enter State...",
  city: "City",
  state_name: [
    "Select State",
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY"
  ],
  enter_zip: "Enter ZIP code...",
  zip_code: "Zip Code",
  enter_escrow_number: "Enter escrow number...",
  escrow_number: "Escrow Number",
  estimated_closing_date: "Estimated Closing Date",
  month: "Month",
  month_name: ["June", "July", "August"],
  year: "Year",
  years: ["2019", "2018", "2017"],
  save_officer_for_future_order: "+ Save officer for future order",
  report_selection: "Report Selection",
  order_confirmation: "Order Confirmation",
  escrow_invoice: "Escrow Invoice",
  price_one: "$99.95",
  price_two: "$79.95",
  promotional_price: "Limited time pricing $50.00",

  this_report_exclusively:
    "This report is exclusively for transactions described within the ",
  ca_civil_code: "CA civil code 1103.1",
  add_another_report: "Add Another Report",
  delivery_report_to: "Deliver Report to",
  delivery_message: "Delivery Message",
  include_special_details_about_your_order:
    "Include any special instructions about your order.",
  nhd_report_for: "NHD Report For",
  address: "Address",
  nhd_report_address: "801 West 135th Street, Gardena, CA, 90274, USA",
  county: "County",
  property_apn: "Property APN",
  property_apn_value: "6117033900",
  order_report: "Order Report",
  report_county_default: { countyNum: 0, text: "" },
  order_confirmation_thankyou:
    "Thank you for your order, make sure to check your email!",
  your_order_no: "Your order number:",
  another_order: "Place another order",
  need_AHS_warranty: "Need to order a American Home Shield Home Warranty?",
  AHS_link: "http://pro.ahs.com",
  click_here: "Click Here",
  add_another_recipient: "Add another recipient",
  apn: "APN",
  get_started_button_text: "Get Started",
  change: "Change",
  already_have_account: "Already have an account?",
  terms_header: "USHD Website Terms of Use",
  terms_last_updated: "October 15, 2020",
  enter_city: "Enter city...",
  enter_email:"Enter email...",
  enter_unit_number:"Enter unit #...",
  unit_number:"Unit #",
  real_estate_company_address:"Real Estate Company Address",
  update_apn:"Update your APN",
  update_address:"Update your address",
  accurate_info_message : "Accurate information will improve the delivery time of the report",
  apn_caption: "APN (Assessor's Parcel Number)",
  logout_message_title : "Session Timeout",
  logout_message : "You are going to be logged out in ",
  idletimeout_message_title : "Idle Timeout",
  enter_apn : "Enter APN",
  hsp_title: "Home Warranty Plan",
  hsp_message:"Click here if you would like to order a Home Warranty Plan. An AHS team member will reach out for assistance.",
  filter_by_number_name_address : 'Filter by apn, order number or address (at least 2 chars)',
  order_history_header_text : "You can view your order history and the current status of your report.  Self service options for our customers like adding escrow information and sending copies to additional recipients will be available soon",
  };

export const ULTIMATE_BUNDLE = {
  TEXT1 : `With this bundled option, you are selecting the American Home Shield NHD Tax & 
        Environmental Report for $25 (originally $99.95) and American Home Shield home warranty coverage to include 
        the ShieldComplete plan for the buyer or the Seller Coverage Option (payment for home warranty coverage is 
        billed separately).`,
  TEXT2 : `At closing, payment is due for Buyer Coverage and the Seller Coverage Option. 
        The home warranty (ShieldComplete) will provide the buyer with 1 year of coverage effective the date of closing.`,
  TEXT3 : `*One rekey service is included in ShieldComplete, no
        Trade Service Call Fee is required. Thereafter, the Trade Service Call Fee applies. Rekey service is
        an added benefit and may be offered by a third-party.`,
  TEXT4 : `An American Home Shield® home warranty covers the repair
        or replacement of many major components of home systems and appliances, but not necessarily the
        entire system or appliance. Limitations and exclusions apply. See agreement for details by visiting
        ahs.com/contracts.`,
  TEXT5 : `*Available for price of Trade Service Fee call ($100)`,
  TEXT6 : `**HVAC Tune-ups are available seasonally (spring: A/C; fall: heating) and offered by a third party. With 
        the ShieldComplete plan, the first tune-up of one unit is included for free per contract term. Service dates 
        are limited and may vary based upon location. For buyer use only.`,
  TEXT7 : `***Subject to a $1,500 cap during the listing period.
        Renewable upon review at the discretion of American Home Shield® after 6-month period, up to 12
        months.`
};

export default MESSAGE;
