import React, {useState} from "react";
import {Button, CheckboxGroup, RadioGroup} from '@ftdr/blueprint-components-react';
import ModalBugFix from "../modal/ModalBugFix";
import {InputField} from "../index";
import MESSAGE from "helpers/Message";

const UltimateBundleSelectionModal = (props) => {

    const [propertyType, setPropertyType] = useState("single");
    const [propertySize, setPropertySize] = useState("up_to_5000");
    const [addOns, setAddOns] = useState([]);
    const [buyerName, setBuyerName] = useState("");
    const [buyerPhone, setBuyerPhone] = useState("");
    const [warrantyType, setWarrantyType] = useState("shieldComplete");

    const warrantyTypeRadioItems = [
        { value: 'shieldComplete', label: 'Shield Complete' },
        { value: 'shieldPlus', label: 'Shield Plus' },
        { value: 'shieldEssential', label: 'Shield Essential' },
    ];

    const propertyTypeRadioItems = [
        { value: 'single', label: 'Single Family Residence' },
        { value: 'condo_town_mobile', label: 'Condominium / Town Home / Mobile Home' },
        { value: 'duplex', label: 'Duplex' },
        { value: 'triplex', label: 'Triplex' },
        { value: 'fourplex', label: 'Fourplex' },
    ];

    const propertySizeRadioItems = [
        { value: 'up_to_5000', label: 'Up to 5,000 square feet'},
        { value: '5001_to_9999', label: '5,001 - 9,999 square feet'},
    ];

    const hspOptionCheckItems = [
        { value: 'electronics', label: 'Electronics plan $240' },
        { value: 'wine_refrigerator', label: 'Wine and wet bar refrigerators $15' },
        { value: 'well_pump', label: 'Well pump $100' },
        { value: 'septic_pump', label: 'Septic system pumping & septic sewer ejector pump $75' },
        { value: 'pool_spa', label: 'Pool and built-in spa $195' },
        { value: 'salt_water_pool_spa', label: 'Salt water pool & built-in spa $325' },
        { value: 'portable_spa', label: 'Portable spa $195' },
        { value: 'without_ac', label: 'Without A/C' },
    ];

    const actions = [
        <Button
            id="ubp-selections-modal-confirm"
            label="Confirm Selections"
            size="medium"
            onClick={() => props.onConfirmSelections(propertyType, propertySize, addOns, warrantyType, buyerName, buyerPhone)}
        />,
        <Button
            id="ubp-selections-modal-cancel"
            label="Cancel"
            size="medium"
            onClick={props.onClose}
        />
    ];

    return (
        <ModalBugFix
            open={true}
            hideClose={true}
            actions={actions}
            modal={true}
            closeOnOutsideClick={false}
        >
            <>
                <p className="mb-4">In order to place your order, we need more details about your property.</p>

                <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 pre-bds">
                    <InputField
                        showOptionalKey={true}
                        placeholder={MESSAGE.enter_full_name}
                        label={"Buyer Name"}
                        className="form-control form-placeholder"
                        handleChange={(e) => setBuyerName(e?.target?.value || "")}
                        maxLength={100}
                        type="text"
                        name="Buyer Name"
                    />
                    <InputField
                        showOptionalKey={true}
                        placeholder={MESSAGE.enter_phone_number}
                        label={"Buyer Phone"}
                        className="form-control form-placeholder"
                        handleChange={(e) => setBuyerPhone(e?.target?.value || "")}
                        maxLength={100}
                        type="text"
                        name="Buyer Phone"
                    />
                </div>

                <RadioGroup
                    formField
                    radios={warrantyTypeRadioItems}
                    label="(select one)"
                    value={warrantyType}
                    onChange={setWarrantyType}
                    className="mb-4"
                />

                <RadioGroup
                    formField
                    radios={propertyTypeRadioItems}
                    label="This property is a (select one)"
                    value={propertyType}
                    onChange={setPropertyType}
                    className="mb-4"
                />
                <RadioGroup
                    formField
                    radios={propertySizeRadioItems}
                    label="The size of this property is (select one)"
                    value={propertySize}
                    onChange={setPropertySize}
                    className="mb-4"
                />
                <CheckboxGroup
                    formField
                    checkboxes={hspOptionCheckItems}
                    label="Please indicate which options, if any, you would like to include in your order:"
                    value={addOns}
                    onChange={setAddOns}
                    className="mb-4"
                />
            </>
        </ModalBugFix>
    );
};

export default UltimateBundleSelectionModal;