import React from "react";
import LabelField from "./LabelField";
import DatePicker from "react-date-picker";

/*
Please refer https://github.com/wojtekmaj/react-date-picker
to customize calender picker.
there are plenty of event handler like month change, day change etc
you can add them inprops and in    <DatePicker ...props />
*/

interface CalenderPickerProps {
  name: string;
  value?: Date;
  required?: boolean;
  readOnly?: boolean;
  label?: string;
  className?: string;
  showOptionalKey?: boolean;
  isSubmitted?: boolean;
  calendarIcon?: JSX.Element;
  clearIcon?: JSX.Element;
  format?: string;
  isOpen?: boolean;
  locale?: string;
  maxDate?: Date;
  minDate?: Date;
  showLeadingZeros?: boolean;
  calendarType?: "ISO 8601" | "US" | "Arabic" | "Hebrew" | undefined;
  showWeekNumbers?: boolean;
  handleChange?: (event: any, datePickerObject?: any) => void;
}

interface InputStateModel {
  isValidPhoneNumber: boolean;
  isValidEmail: boolean;
}

const CalenderPicker: React.FC<CalenderPickerProps> = ({
  className,
  name,
  value,
  required,
  readOnly,
  label,
  handleChange,
  showOptionalKey,
  isSubmitted,
  calendarIcon,
  clearIcon,
  format,
  isOpen,
  locale,
  maxDate,
  minDate,
  showLeadingZeros,
  calendarType,
  showWeekNumbers
}) => {
  const renderErrorMessages = () => {
    if (isSubmitted && required && !value)
      return (
        <span className="text-danger">
          {label ? label : "This field "} is required.
        </span>
      );
  };

  const datePickerChangeHandler = (selectedDate: Date | Date[]) => {
    if (handleChange) {
      const eventObject = {
        name,
        value: selectedDate,
        type: "DatePicker"
      };
      handleChange(null, eventObject);
    }
  };

  return (
    <div className="form-group">
      {label && (
        <>
          <LabelField
            htmlFor={name}
            className={"label-title " + (required ? "req" : "")}
            labelText={label}
          />
          {showOptionalKey && (
            <span className="text-muted font-weight-normal">
              &nbsp;(optional)
            </span>
          )}
        </>
      )}

      <DatePicker
        showWeekNumbers={showWeekNumbers}
        calendarType={calendarType}
        calendarIcon={calendarIcon}
        className={className}
        clearIcon={clearIcon}
        disabled={readOnly}
        format={format}
        isOpen={isOpen}
        locale={locale}
        maxDate={maxDate}
        minDate={minDate}
        name={name}
        onChange={datePickerChangeHandler}
        required={required}
        showLeadingZeros={showLeadingZeros}
        value={value}
      />
      {renderErrorMessages()}
    </div>
  );
};

export default CalenderPicker;
