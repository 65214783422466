import React, { useEffect, useState } from 'react';

import {
  Button,
  DateTimeInput,
  Dialog,
  Popover,
  PopoverContent,
  Select,
  Text,
} from '@ftdr/blueprint-components-react';
import { dateTypes, defaultFilterDatePresets } from '../../constants/dates';
import { StatusMenuType } from '../../components/filter/OrderStatusFilter';
import './DateRangeSelectionModal.css';

interface DateRangeProps {
  open: boolean;
  initialValues: {
    type: dateTypes;
    presetTitle: string;
    start: Date;
    end: Date;
  };
  onChange(rangeType: string, preset: string, start: Date, end: Date): void;
  onClose(): void;
  statusMenuType?: StatusMenuType;
}

interface DateRangePreset {
  value: string,
  title: string,
}

const DateRangeSelectionModal: React.FC<DateRangeProps> = (props: DateRangeProps) => {

  const [type, setDateType] = useState<string>(props.initialValues?.type);
  const [presetTitle, setPresetTitle] = useState<string>(props.initialValues?.presetTitle || '');
  const [presets, setPresets] = useState<DateRangePreset[]>([]);

  //@ts-ignore
  const [startDate, setStartDate] = useState<Date>(null);
  //@ts-ignore
  const [endDate, setEndDate] = useState<Date>(null);

  const onApply = () => {
    props.onChange(type, presetTitle, startDate, endDate);
    props.onClose();
  };

  const onClose = () => {
    props.onClose();
  };

  const canSubmit = (): boolean => {
    return type !== null && presetTitle !== '' && startDate !== null && endDate !== null;
  };

  const dateInputVisible = (): boolean => {
    return presetTitle === 'custom';
  };

  const onSelect = (preset: any): void => {
    setPresetTitle(preset?.title);
  };

  const setDates = (start: Date, end: Date) => {
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    let p: DateRangePreset[] = [];
    p = defaultFilterDatePresets.map(filter => {
      return {
        value: filter.label,
        title: filter.id
      };
    });

    setPresets(p);

  }, [type]);

  useEffect(() => {
    if (presetTitle === '') {
      return;
    }
    const allFilters = [...defaultFilterDatePresets];
    const filter = allFilters.find(el => el.id === presetTitle);
    if (filter) {
      //@ts-ignore
      setStartDate(filter.start);
      //@ts-ignore
      setEndDate(filter.end);
    }
  }, [presetTitle]);

  useEffect(() => {
    setDateType(props.initialValues?.type);
    setPresetTitle(props.initialValues?.presetTitle || '');
  }, [props.initialValues]);

  const buttons = [
    <Popover
      placement="bottom"
      triggerInteraction='hover'
      content={(popoverContentProps) => (
        <PopoverContent {...popoverContentProps}>
          <Text className="max-w-xs" color="gray" variant="caption">
            All fields must be filled in to apply date range.
          </Text>
        </PopoverContent>
      )}
      disabled={canSubmit()}
      className="mr-3 w-full"
    >
      <Button id="dateRangePicker-apply" label="Apply" onClick={onApply} disabled={!canSubmit()} />
    </Popover>,
    <Button id="dateRangePicker-cancel" label="Cancel" onClick={onClose} autoFocus={true} variant='outlined' />
  ];

  return (
    <div className="date-range-dialog">
      <Dialog
        id="dateRangePicker-dialog"
        className="pop-up-window"
        header="Date Range"
        actions={buttons}
        open={props.open}
        onClose={onClose}
        showBackdrop={true}
      >
        <>
          <Text
            id="dateRangePicker-text"
            className="py-2"
          >Filter your orders by date range.
          </Text>
          <div className="w-80 mt-4">
            <Select
              id="dateRangePicker-presets-options"
              options={presets}
              onSelect={onSelect}
              label=''
              placeholder='Select a date range...'
              multiSelect={false}
              selected={presets.find(el => el.title === presetTitle)}
            />
            {dateInputVisible() && (
              <div className="dateRangePicker">
                <DateTimeInput
                  id="dateRangePicker-dateTimeInput"
                  label=''
                  //@ts-ignore
                  onDateSelect={setDates}
                  selectsRange={true}
                  selectedDate={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  datePicker={true}
                  autoComplete='off'
                />
              </div>
            )}
          </div>
        </>
      </Dialog>
    </div>
  );
};

export default DateRangeSelectionModal;