import axios, { AxiosResponse } from "axios";
import ConfigHelper from "helpers/ConfigHelper";
import Address from "../../components/confirmAddress/Models/address";
import ApiHelper from "../ApiHelper";
import {TrackGAEvent} from "../../components/gaTracking/GATracking";

export default abstract class AddressDataService {

    public static ValidateAddress(address: Address) {
        let config = ApiHelper.GetConfig();
        
        return axios.get(ConfigHelper.ApiBaseUrl + `address/address?street=${address.streetAddress}&unit=${address.unitNumber}&zip=${address.zipCode}&state=${address.state}&city=${address.city}`, config ).then((response:AxiosResponse) => {
            return new Address(response.data.address.street1, response.data.address.city, response.data.address.state, response.data.address.zip, true, response.data.address.unit_value);
        })
            .catch(() => {
                TrackGAEvent("service", "failed", "address data service failed");
                return new Address("", "", "", "", false);
            });
    }
}

