export enum FilterOperation {
    ADD = 'add',
    REMOVE = 'remove',
    PAGE_CHANGE = 'pageChange',
    SEARCH_INPUT = 'searchInput'
  }
  
  export enum FilterType {
    STATUS = 'status',
    DATE = 'date',
    TEXT = 'text',
    PAGE = 'page',
    ORDER_SORT = 'orderSort',
    CLEAR_FILTER = 'clearFilter'
  }