import React from "react";
import "./About.css";

interface AboutProps {
  about_title: string;
  about_info: string;
}

const About: React.FC<AboutProps> = ({ about_title, about_info }) => {
  return (
    <div className="about-section">
      <h6 className="about-title">{about_title}</h6>
      <p className="about-info">{about_info}</p>
    </div>
  );
};

export default About;
