import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { Tabs, Tab } from "react-bootstrap";
import { OrderReportCardModel } from "components/Models";
import "./OrderReportCard.css";
import { ApnDataService, AuthService } from "api";
import LocalStorageHelper from "helpers/LocalStorageHelper";
import { ReactComponent as ErrorIcon } from "assets/icons/form/warning.svg";
import DropdownWithValues from "../formFields/DropDownWithValues";
import counties from "helpers/Counties";
import Address from "../confirmAddress/Models/address";
import { TrackGAEvent } from "../gaTracking/GATracking";
import FullAddressInput from "../fullAddressInput/fullAddressInput";
import AddressDataService from "../../api/services/addressDataService";
import ModalChildren from "../modalChildren/ModalChildren";
import ConfirmAddress from "../confirmAddress/confirmAddress";
import { isNumber } from "util";
import ConfigHelper from "helpers/ConfigHelper";
import { PATHS } from 'helpers/Constants';

interface OrderReportCardProps {
  defaultFields: OrderReportCardModel;
  updateOrderFormHandler?: Function;
}

const OrderReportCard = (props: OrderReportCardProps) => {
  const [streetAddress, setStreetAddress] = useState('');
  const [unit, setUnit] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [isAddressInvalid, setIsAddressInvalid] = useState(false);

  const [isStreetAddressRequired, setIsStreetAddressRequired] = useState(false);
  const [isCityRequired, setIsCityRequired] = useState(false);
  const [isZipCodeRequired, setIsZipCodeRequired] = useState(false);

  const [foundAddress, setFoundAddress] = useState<Address>();
  const [selectedAddress, setSelectedAddress] = useState<Address>();

  const [apn, setApn] = useState('');
  const [apnCity, setApnCity] = useState('');
  const [apnZip, setApnZip] = useState('');
  const [county, setCounty] = useState(0);
  const [isApnInvalid, setIsApnInvalid] = useState(false);
  const [isApnCityRequired, setIsApnCityRequired] = useState(false);
  const [isApnZipRequired, setIsApnZipRequired] = useState(false);
  const [isApnRequired, SetApnRequired] = useState(false);
  const [isCountyRequired, SetCountyRequired] = useState(false);

  const history = useHistory();

  const handleClickContinueApn = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    logSearchByAPN();
    LocalStorageHelper.ClearLocalStorage();
    let selectedCounty = (county === 0) ? 1 : county;
    let isValid = true;
    ResetValidationFlags();

    isValid = checkAPNAddressValid();

    if (apn.trim() == "") {
      SetApnRequired(true);
      isValid = false;
    }
    if (!county) {
      SetCountyRequired(true);
      isValid = false;
    }

    if (isValid) {
      //set city and zip info in localstorage
      let address = new Address();
      address.city = apnCity;
      address.zipCode = apnZip;

      localStorage.setItem(LocalStorageHelper.NHD_APN, apn);
      localStorage.setItem(LocalStorageHelper.NHD_COUNTY, selectedCounty.toString());
      localStorage.setItem(LocalStorageHelper.NHD_ADDRESS, JSON.stringify(address));

      continueToOrder();
    } else {
      return;
    }
  };

  const ResetValidationFlags = () => {
    setIsApnInvalid(false);
    setIsApnCityRequired(false);
    setIsApnZipRequired(false);
    SetApnRequired(false);
    SetCountyRequired(false);
  }

  const checkAPNAddressValid = () => {
    let isValid = true;
    if (apnCity.trim() == "") {
      setIsApnCityRequired(true);
      isValid = false;
    }

    if (apnZip.trim() == "") {
      setIsApnZipRequired(true);
      isValid = false;
    }
    return isValid;
  }

  const continueToOrder = () => {
    if (props.updateOrderFormHandler) {
      props.updateOrderFormHandler({
        selectedAPN: localStorage.getItem(LocalStorageHelper.NHD_APN),
        selectedCounty: localStorage.getItem(LocalStorageHelper.NHD_COUNTY),
        selectedAddress: localStorage.getItem(LocalStorageHelper.NHD_ADDRESS)
      });
    } else if (AuthService.isLoggedIn()) {
      let pathWithBaseFolder = ConfigHelper.GetBasePath() + PATHS.OrderForm;
      history.push(pathWithBaseFolder);
    } else {
      window.location.replace(AuthService.getRegisterURL() + PATHS.OrderForm);
    }
  };

  const isAddressFilled = () => {
    return (city.trim() !== "" && streetAddress.trim() !== "" && zipCode.trim() !== "");
  };

  const trimAddressInputs = () => {
    setCity(city.trim());
    setStreetAddress(streetAddress.trim());
    setZipCode(zipCode.trim());
    setUnit(unit.trim());
  };

  const showAddressRequiredMessages = () => {
    if (city.trim() === "") {
      setIsCityRequired(true);
    }

    if (streetAddress.trim() === "") {
      setIsStreetAddressRequired(true);
    }

    if (zipCode.trim() === "") {
      setIsZipCodeRequired(true);
    }
  };

  const handleClickContinueAddress = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    logSearchByStreetAddress();
    trimAddressInputs();

    if (!isAddressFilled()) {
      showAddressRequiredMessages();
      return
    }

    let address = new Address(streetAddress, city, 'CA', zipCode, false, unit);
    LocalStorageHelper.ClearLocalStorage();

    AddressDataService.ValidateAddress(address).then(foundAddress => {
      if (address.Equals(foundAddress)) {
        handleAddress(address);
      } else {
        setFoundAddress(foundAddress);
        setSelectedAddress(address);
        setIsAddressInvalid(true);
      }
    });
  };

  const handleAddress = (address: Address) => {
    logSearchByStreetAddress();
    LocalStorageHelper.ClearLocalStorage();

    ApnDataService.addressToAPN(address).then(response => {
      const findCounty = counties.find(
        county => county.getText() === response.county
      );
      const countyNum = findCounty ? findCounty.getValue() : 0;
      const addressModified = { ...address };
      addressModified.city = addressModified.city.trim()
        ? addressModified.city
        : response.city;
      const apn = response.apn || "";

      saveAddress(JSON.stringify(addressModified), apn, countyNum.toString());

      continueToOrder();
    });
  };

  const saveAddress = (address: string, apn: string, county: string) => {
    localStorage.setItem(LocalStorageHelper.NHD_ADDRESS, address);
    localStorage.setItem(LocalStorageHelper.NHD_APN, apn);
    localStorage.setItem(LocalStorageHelper.NHD_COUNTY, county);
  };

  const handleCountyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let option = e.target.selectedOptions.item(0);
    let value = 1;

    if (option != null) {
      value = parseInt(option.value);
    }

    setCounty(value);
  };

  const handleApnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let apn = e.target.value;
    setApn(apn);
  };

  const handleApnCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let apnCity = e.target.value;
    setApnCity(apnCity);
  };

  const handleApnZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    let apnZip = e.target.value;

    //allow only numbers
    let regexp = /^[0-9\b]+$/;

    // if value is not blank, then test the regex
    if (apnZip === '' || regexp.test(apnZip)) {
      setApnZip(apnZip);
    }

  };

  const handleStreetAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let streetAddress = e.target.value;
    setStreetAddress(streetAddress);
  };

  const handleUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let unit = e.target.value;
    setUnit(unit);
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let city = e.target.value;
    setCity(city);
  };

  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let zipCode = e.target.value;
    setZipCode(zipCode);
  };

  const logSearchByStreetAddress = () => {
    TrackGAEvent("text field", "type", "use street address");
  };

  const logSearchByAPN = () => {
    TrackGAEvent("text field", "type", "use apn");
  };

  let apnErrorClass = isApnInvalid ? " apn-error-box" : "";
  const apnCityMaxLength = 30;
  const apnZipCodeMaxLength = 15;

  return (
    <div className={"order-report-card"}>
      <h5 className="order-report-card-title">
        {props.defaultFields.order_report_card_title}
      </h5>
      <Tabs
        defaultActiveKey={props.defaultFields.address}
        id="report-tabs"
      >
        <Tab
          eventKey={props.defaultFields.address}
          title={props.defaultFields.use_address}
        >
          <FullAddressInput
            streetAddress={streetAddress}
            unitNumber={unit}
            city={city}
            zipCode={zipCode}

            isStreetRequired={isStreetAddressRequired}
            isCityRequired={isCityRequired}
            isZipRequired={isZipCodeRequired}

            onStreetAddressChange={handleStreetAddressChange}
            onUnitChanged={handleUnitChange}
            onCityChange={handleCityChange}
            onZipCodeChange={handleZipCodeChange}

            onClose={() => { }}
          />

          <ul className="list-inline text-right">
            <li className="list-inline-item">
              <button
                type="submit"
                className={"btn blue-btn"}
                onClick={handleClickContinueAddress}
              >
                {props.defaultFields.continue_button_text}
              </button>
            </li>
          </ul>
        </Tab>

        <Tab
          eventKey={props.defaultFields.apn}
          title={props.defaultFields.use_apn}
        >
          <div className="row">
            <div className="col-7">
              <div>
                <label htmlFor="collect-street-address" className="label-title req">
                  <b>APN</b>
                </label>
                <input
                  type="text"
                  className={"apn-search-input form-control " + apnErrorClass}
                  name="propertyAddress"
                  placeholder={props.defaultFields.search_by_property_apn}
                  value={apn}
                  onChange={handleApnChange}
                />
                {
                  isApnRequired && <p className="text-danger">APN is required</p>
                }
              </div>
            </div>

            <div className="col-5">
              <div>
                <label htmlFor="collect-street-address" className="label-title req">
                  <b>County</b>
                </label>
                <DropdownWithValues
                  className={
                    "apn-county-dropdown form-control " + apnErrorClass
                  }
                  options={counties}
                  required={false}
                  name="propertyCounty"
                  defaultOption="Select one"
                  handleChange={handleCountyChange}
                />
                {
                  isCountyRequired && <p className="text-danger">County is required</p>
                }
              </div>
            </div>
          </div>
         
          <div className="row">
            <div className="col-7">
              <div>
                <label htmlFor="collect-street-address" className="label-title req">
                  <b>City</b>
                </label>
                <input
                  type="text"
                  className={"apn-city-search-input form-control"}
                  name="propertyApnCity"
                  placeholder="Enter City"
                  value={apnCity}
                  onChange={handleApnCityChange}
                  maxLength={apnCityMaxLength}
                />
                {
                  isApnCityRequired && <p className="text-danger">City is required</p>
                }
              </div>
            </div>

            <div className="col-5">
              <div>
                <label htmlFor="collect-street-address" className="label-title req">
                  <b>Zip</b>
                </label>
                <input
                  type="text"
                  className={"apn-zip-search-input form-control"}
                  name="propertyApnZip"
                  placeholder="Enter Zip"
                  value={apnZip}
                  onChange={handleApnZipChange}
                  maxLength={apnZipCodeMaxLength}
                />
                {
                  isApnZipRequired && <p className="text-danger">Zip is required</p>
                }
              </div>
            </div>
          </div>
          <div>
            <ul className="list-inline text-right">
              <li className="list-inline-item">
                <button
                  type="submit"
                  className={"btn blue-btn btn-search-by-apn"}
                  onClick={handleClickContinueApn}
                >
                  {'Continue'}
                </button>
              </li>
            </ul>
          </div>
        </Tab>
      </Tabs>

      {isAddressInvalid && (
        <ModalChildren>
          <ConfirmAddress
            addressFromBackend={foundAddress}
            addressFromInput={selectedAddress}
            onEditAddress={() => { setIsAddressInvalid(false) }}
            onUseAddress={handleAddress}
          />
        </ModalChildren>
      )}

    </div>
  );
};


export default OrderReportCard;
