import React from "react";
import LabelField from "./LabelField";
import './FormFields.css';

interface DropdownProps {
  label?: string;
  name: string;
  className: string;
  defaultOption?: any;
  required?: boolean;
  options: any[];
  handleChange?: any;
  isSubmitted?: boolean;
  value?: string;
  disabled?:boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  name,
  className,
  defaultOption,
  required,
  options,
  handleChange,
  isSubmitted,
  value,
  disabled
}) => {
  return (
    <>
      {label && (
        <LabelField
          htmlFor={name}
          className={"label-title " + (required ? "req" : "")}
          labelText={label}
        />
      )}
      <select
        className={className}
        value={value}
        name={name}
        onChange={handleChange}
        disabled={disabled}
      >
        {defaultOption && <option>{defaultOption}</option>}
        {options
          ? options.map(item => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })
          : ""}
      </select>
      {isSubmitted && required && !value && (
        <span className="text-danger">
          {label ? label : "This field "} is required.
        </span>
      )}
    </>
  );
};

export default Dropdown;
