import React from "react";
import { EmailToModel, FormFieldModel } from "components/Models";
import { InputField, CheckBox } from "components";

interface EmailToModelProps {
  defaultFields: EmailToModel;
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fieldValues: FormFieldModel;
}

const EmailTo: React.FC<EmailToModelProps> = ({
  defaultFields,
  onChangeHandler,
  fieldValues
}) => {
  return (
    <div className="form-group">
      <div className="row">
        <div className="col-md-6">
          <InputField
            readOnly={defaultFields.isReadOnly}
            type={defaultFields.isTextField ? "text" : "email"}
            name={`recipientEmail-${defaultFields.recipientID}`}
            required={false}
            className="form-control form-placeholder"
            value={
              defaultFields.isTextField
                ? defaultFields.email
                : fieldValues[`recipientEmail-${defaultFields.recipientID}`]
                    .value
            }
            handleChange={onChangeHandler}
          />
        </div>
        <div className="col-md-3 text-center pt-10">
          <CheckBox
            disabled={defaultFields.isOrderConfirmDisabled}
            name={`recipientOrderConfirmationCheckbox-${defaultFields.recipientID}`}
            id={`recipientOrderConfirmationCheckbox-${defaultFields.recipientID}`}
            className="custom-control-input"
            checked={
              fieldValues[
                `recipientOrderConfirmationCheckbox-${defaultFields.recipientID}`
              ].value
            }
            handleChange={onChangeHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default EmailTo;
