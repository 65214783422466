import React, { useState } from 'react';
import { FilterOperation, FilterType } from '../../constants/dashboardFilters';
import { Accordion, AccordionSection, Button, IconCalendar} from '@ftdr/blueprint-components-react';
import OrderStatusFilter from '../filter/OrderStatusFilter';
import { formattedDate } from '../../helpers/Utils';
import DateRangeSelectionModal from '../../components/dateRange/DateRangeSelectionModal';
import './OrderHistory.css';

const ListFilter = props => {

  const [showDatePicker, setShowDatePicker] = useState(false);

  // TODO Consider moving this into the DateRange component directly
  const handleDateRangeFilter = (dateType: string, presetTitle: string, start, end) => {

    if (start && end) {
      const startDateStr = formattedDate(start);
      const endDateStr = formattedDate(end);

      props.addFilter(
        undefined,
        {
          operation: FilterOperation.ADD,
          type: FilterType.DATE,
          payload: {
            date: {
              start,
              end,
              type: dateType,
              presetTitle
            }
          },
          label: `Order Date: ${startDateStr} - ${endDateStr}`
        }
      );
    }
  };

  const dateRenderer = () => {
    return (
      <Button onClick={() => setShowDatePicker(true)} width="full" className="pt-2 pb-2 pl-0" size="small"
        variant="ghost" label="Date Range" labelAlign="left" startIcon={<IconCalendar
          className="button-medium-icon ml-0-override" />} />
    );
  };

  return (
    <div className="mg-10">
      <Accordion mode="multiple">
        <AccordionSection label={'Order Status'}>
          <OrderStatusFilter
            addFilter={props.addFilter}
            activeFilters={props.activeFilters.filter(filter => filter.type === FilterType.STATUS)}
            filterAlerts={{ ...props.statusAlerts }}
            roleIDType={props.userRole}
            statusMenuType={props.statusMenuType}
          />
        </AccordionSection>
        <AccordionSection label="Date Range" headerRenderer={dateRenderer} />
      </Accordion>
      <DateRangeSelectionModal
        open={showDatePicker}
        initialValues={props.activeFilters.filter(filter => filter.type === FilterType.DATE)[0]?.payload.date}
        statusMenuType={props.statusMenuType}
        onChange={handleDateRangeFilter}
        onClose={() => setShowDatePicker(false)}
      />
    </div>
  );
};

export default ListFilter;
