import React from "react";

interface ButtonProps {
  onClick?: any;
  buttonText?: string;
  className?: string;
  type?: any;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({ onClick, buttonText, className, type, disabled }) => {
  return (
    <button
        type={type}
        className={className + (disabled ? " disabled" : "")}
        onClick = {onClick}
        disabled={disabled}
    >
      {buttonText}
    </button>
  );
};

export default Button;
