import React from 'react';
import IdleTimer from 'react-idle-timer';
import LogoutModal from './components/logout/logoutModal';
import ModalChildren from "./components/modalChildren/ModalChildren";
import { AuthService } from "api";
import ConfigHelper from "helpers/ConfigHelper";
import IdleTimeModal from './components/logout/idleTimeModal';

interface ILayoutState {
    expires_time: number;
    warning_time: number;
    showIdleWaitModal: boolean;
    showLogoutModal: boolean;
}

class Layout extends React.Component<any, ILayoutState>{

    public idleTimer: any;
    private force_logout: any;

    constructor(props) {
        super(props);

        this.state = {
            expires_time: ConfigHelper.GetConfig("IdleTimeout_Expires_Time_InMilliseconds"),
            warning_time: ConfigHelper.GetConfig("IdleTimeout_Warning_Time_InMilliseconds"),
            showIdleWaitModal: false,
            showLogoutModal: false
        }
    }

    public componentDidMount() {
        if (AuthService.isLoggedIn()) {
            const logout_Warning_Time = ConfigHelper.GetConfig("Logout_Warning_Time_InMilliseconds");

            let expires_in_milliSecs = AuthService.getTokenExpirationTime();
            let expires_in_date = new Date(expires_in_milliSecs * 1000);

            let now = new Date();

            //log out time
            let logout_time = expires_in_date.getTime() - now.getTime();

            //show message box before warning mins
            let warning_time = expires_in_date.getTime() - now.getTime() - parseInt(logout_Warning_Time);

            //warn the user
            setTimeout(() => {
                this.setState({ showLogoutModal: true });
            }, warning_time);

            //log out the user
            setTimeout(() => {
                this.onLogOutOk();
                AuthService.logout();
            }, logout_time);
        }
    }

    public onLogOutOk = () => {
        this.setState({ showLogoutModal: false, showIdleWaitModal: false });
    }

    public onIdle = () => {
        if (AuthService.isLoggedIn()) {
            this.setState({ showIdleWaitModal: true });
            this.force_logout = setTimeout(() => {
                this.setState({ showIdleWaitModal: false });
                AuthService.logout();
            }, this.state.warning_time);
        }
    }

    public onIdleTimeoutWarnYes = () => {
        this.setState({ showIdleWaitModal: false, showLogoutModal: false });
        clearTimeout(this.force_logout);
    }

    public onIdleTimeoutWarnNo = () => {
        this.setState({ showIdleWaitModal: false, showLogoutModal: false });
        AuthService.logout();
    }

    public render() {

        return <>
            <IdleTimer
                ref={ref => this.idleTimer = ref}
                element={document}
                onIdle={this.onIdle}
                debounce={1000}
                timeout={this.state.expires_time - this.state.warning_time}
            />

            {this.props.children}

            {this.state.showLogoutModal && AuthService.isLoggedIn &&
                <ModalChildren>
                    <LogoutModal onOK={this.onLogOutOk} />
                </ModalChildren>
            }

            {this.state.showIdleWaitModal && AuthService.isLoggedIn &&
                <ModalChildren>
                    <IdleTimeModal onYes={this.onIdleTimeoutWarnYes} onNo={this.onIdleTimeoutWarnNo} />
                </ModalChildren>
            }
        </>;
    }
}

export default Layout;