export enum OrderStatus {
    Active = "Active",
    Canceled = "Canceled", 
    Closed = "Closed",
    Expired_Off_Market = "Expired/Off Market",
    Paid = "Paid",
    Under_Contract = "Under Contract"
}


export const orderStatusDictionary = {
    [OrderStatus.Active] : "Active",
    [OrderStatus.Canceled] : "Cancelled",
    [OrderStatus.Closed] : "Closed",
    [OrderStatus.Expired_Off_Market] : "Expired/Off Market",
    [OrderStatus.Paid] : "Paid",
    [OrderStatus.Under_Contract] : "Under contract"
} as const
