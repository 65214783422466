import dateFnFormat from 'date-fns/format';
import { capitalize, merge, values, keyBy } from 'lodash';
import dateFnParse from 'date-fns/parse';

export const FormatPhoneNumber = v => {
    let formattedValue = "";
    if (v) 
    {
        const value = v.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        formattedValue = value;
        if (value) {
            formattedValue = !value[2]
                ? value[1]
                : `(${value[1]}) ${value[2]}${value[3] ? `-${value[3]}` : ""}`;
        }        
    }
    return formattedValue;
};

export const CleanPhone = (phone) => {

    return phone.trim() ? phone.replace("-", "").replace("(", "").replace(")", "").replace(" ", "") : "";
};

export enum DateFormat {
    DEFAULT = 'MM/dd/yyyy',
    ISO8601  = 'yyyy-MM-dd',
    FULL = 'MMMM d, yyyy',
  }

  export function parseDate(date: string): Date {

    // if (!date) {
    //   console.warn("no date provided, returning undefined Date instead");
    //   return undefined;
    // }
  
    // if matches MM/DD/YYYY, then handle it separately
    if (date.match(/\d{2}\/\d{2}\/\d{4}/)) {
      return dateFnParse(date, "MM/dd/yyyy", new Date());
    }
  
    // Otherwise, drop timestamp and proceed
    const dateTokens = date.split("T");
    const dateWithoutTimestamp = dateTokens[0];
  
    return dateFnParse(dateWithoutTimestamp, "yyyy-MM-dd", new Date());
  
  }

  export function formatDate(date: Date|string, layout: DateFormat|string = DateFormat.DEFAULT): string {

    if (!date) {
      return '';
    }
  
    let dateObj: Date;
  
    if (typeof date === 'string') {
      dateObj = parseDate(date);
    } else {
      dateObj = date;
    }
  
    return dateFnFormat(dateObj, layout);
  }

  export function formattedDate(date: Date): string {
    return formatDate(date, DateFormat.DEFAULT) || "";
  }


  /** @deprecated use formatDate(date, DateFormat.ISO8601)
 * Formats a date object to string YYYY-MM-DD format
 */
export function formatDateToISO8601(date: Date, localizeDate = true): string {
  return formatDate(date, DateFormat.ISO8601);
}

export function classNames(arr) {
  return arr.filter(Boolean).join(' ')
}