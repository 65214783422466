import React, { useEffect, useState } from "react";
import { LabelField, RadioField, TextArea, EmailTo, InputField } from "components";
import {
  EmailToModel,
  ReportSelectionModel,
  FormFieldModel
} from "components/Models";
import PromotionalBanner from "components/PromotionalBanner/PromotionalBanner";
import { Button } from "components";
import PromoCodeModel from '../../components/reportSelectionInfo/Models/PromoCodeModel';
import '../reportSelectionInfo/ReportSelectionInfo.css'
import MESSAGE from "helpers/Message";

interface ReportSelectionInfoProps {
  defaultFields: ReportSelectionModel;
  recipientEmails: EmailToModel[];
  fieldValues: FormFieldModel;
  onChangeHandler: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  otherRecipients: EmailToModel[];
  addOtherRecipient: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onApplyPromocodeHandlerFromOrderForm: () => void;
  promoCodeInfo: PromoCodeModel;
  onHspChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBundleGetInfo: () => void;
}

const ReportSelectionInfo: React.FC<ReportSelectionInfoProps> = ({
  defaultFields,
  recipientEmails,
  fieldValues,
  onChangeHandler,
  otherRecipients,
  addOtherRecipient,
  onApplyPromocodeHandlerFromOrderForm,
  promoCodeInfo,
  onHspChangeHandler,
  onBundleGetInfo,
}) => {
  const [promoCode, setPromoCode] = useState('');

  const onApplyPromocodeClick = (e) => {
    e.preventDefault();
    fieldValues.promoCode.value = promoCode;
    onApplyPromocodeHandlerFromOrderForm();
  }

  return (
    <fieldset>
      <div className="row m-0 pt-30">
        <span className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
          <LabelField
            className="form-title"
            labelText={defaultFields.report_selection}
          />
        </span>
        <span><span className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0"><PromotionalBanner /></span></span>
      </div>

      <div className="radio-div-border custom-radio pb-10 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <RadioField
          value={defaultFields.report_heading_one}
          name={fieldValues.taxReportRadio.name}
          label={defaultFields.report_heading_one}
          id="taxReportRadio-3"
          className="custom-control-input"
          defaultChecked={true}
          handleClick={onChangeHandler}
        />
        <span className="float-right font-weight-bold strikethrough-text">
          {defaultFields.price_one}
        </span>
        <span className="float-right font-weight-bold promotion-price">
          {defaultFields.promotional_price}
        </span>
      </div>

      <div className="radio-div-border custom-radio col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <RadioField
          value={defaultFields.report_heading_two}
          name={fieldValues.taxReportRadio.name}
          label={defaultFields.report_heading_two}
          id="taxReportRadio-4"
          className="custom-control-input"
          handleClick={onChangeHandler}
        />
        <span className="float-right font-weight-bold strikethrough-text">
          {defaultFields.price_two}
        </span>
        <span className="float-right font-weight-bold promotion-price">
          {defaultFields.promotional_price}
        </span>
        <p className="tagline text-gray f-13">
          {defaultFields.this_report_exclusively}
          <a href="/#" className="text-blue text-underline">
            {defaultFields.ca_civil_code}
          </a>
        </p>
      </div>

      <div className="radio-div-border custom-radio col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <RadioField
            value={defaultFields.bundle_text}
            name={fieldValues.taxReportRadio.name}
            label={defaultFields.bundle_additional_text}
            id="taxReportRadio-5"
            className="custom-control-input"
            handleClick={onChangeHandler}
            prefixText={defaultFields.bundle_text}
        />
        <span className="float-right font-weight-bold strikethrough-text">
          {defaultFields.price_one}
        </span>
        <span className="float-right font-weight-bold promotion-price">
          {defaultFields.bundle_price}
        </span>
        <p>
          <strong>ANY </strong>{defaultFields.bundle_coverage_text}
          <span className="float-right">
            <a href="javascript:{}" className="text-blue text-underline" onClick={onBundleGetInfo}>
              {defaultFields.get_more_info}
            </a>
          </span>
        </p>
      </div>
      <div>
        <p>{MESSAGE.bundle_details1} <b>ANY</b> {MESSAGE.bundle_details2} <b>ANY</b>{MESSAGE.bundle_details3}</p>
      </div>


      <div className="col-lg-12 col-md-12 col-sm-4 col-xs-4 pl-0 form-group">

        <label htmlFor="collect-street-address" className="label-title">
          <b>{"Have a Promo Code?"}</b>
        </label>

        <div className="input-group">
          <input
            type="text"
            id="collect-promo-code"
            name="promocode"
            readOnly={false}
            placeholder={"Enter promo code..."}
            value={promoCode}
            onChange={(e) => { setPromoCode(e.target.value) }}
            className={"form-control form-placeholder col-lg-4 col-md-4 mr-2"}
          />
          <button
            onClick={(e) => onApplyPromocodeClick(e)}
            className="btn btn-primary btn-sm btn-block col-lg-2 col-md-2 float-md-right"
            value={"Apply"}
          >{"Apply"}</button>
        </div>
        <div>
          <label htmlFor="collect-street-address" className="label-title promotion-price-thin">
            {"Promo Code is case sensitive"}
          </label>
        </div>
        <div>
          <span className={promoCodeInfo.promocode_valid ? 'validPromoCode' : 'invalidPromoCode'}>
            {promoCodeInfo.promocode_message}
          </span>

        </div>

      </div>

      {/*<div className="row col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 pl-0 hspCheckBoxWidth">
          <input type="checkbox" name="chkSaleByOwner" className="" value="checked"
            onChange={(e) => { onHspChangeHandler(e) }}></input>
        </div>
        <div className="col-lg-11 col-md-11 col-sm-11 col-xs-11 pl-0">
          <b>{MESSAGE.hsp_title}</b><div className="float-right font-weight-bold promotion-price">
          {/*{ This div can be used for promotions when necessary. }}
        </div><br />{MESSAGE.hsp_message} <br /><br />
        </div>
      </div>*/}

      {(recipientEmails.length > 0 || otherRecipients.length > 0) && (
        <div className="row mt-5">
          <legend className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <LabelField
              className="label-title req"
              labelText={defaultFields.delivery_report_to}
            />
          </legend>
        </div>
      )}
      {recipientEmails.map(recipient => {
        if (
          recipient.fieldName === "email3" &&
          fieldValues.escrowYesNo.value === "No"
        ) {
          return null;
        }
        return (
          <EmailTo
            fieldValues={fieldValues}
            onChangeHandler={onChangeHandler}
            key={recipient.fieldName}
            defaultFields={recipient}
          />
        );
      })}
      {otherRecipients.map(recipient => {
        return (
          <EmailTo
            fieldValues={fieldValues}
            onChangeHandler={onChangeHandler}
            key={recipient.recipientID}
            defaultFields={recipient}
          />
        );
      })}

      <div className="row mb-5">
        <button
          className="btn btn-link"
          type="button"
          onClick={addOtherRecipient}
        >
          <span className="plus-icon blue-circle" />
          {defaultFields.add_another_recipient}
        </button>
      </div>

      <div className="form-group">
        <TextArea
          {...fieldValues.DeliveryComment}
          placeholder={defaultFields.include_special_details_about_your_order}
          className="form-control textarea-resize-none"
          label={defaultFields.delivery_message}
          rows={3}
          handleChange={onChangeHandler}
          maxLength={500}
        />
      </div>
    </fieldset>
  );
};

export default ReportSelectionInfo;
