import axios from "axios";
import {PATHS} from "helpers/Constants"
let _config: any = {};

export default abstract class ConfigHelper {

    public static LoadConfig() {
        return axios
            .get(PATHS.ClientConfiguration)
            .then((res) => {
                _config = res.data;
                return true;
            })
            .catch((err) => {
                console.error(err);
                return false;
            });
    }

    static get ApiBaseUrl(): string {
        return _config.ApiBaseUrl || undefined;
    }

    static get IdentityProviderClientID(): string {
        return _config.IdentityProviderClientID || undefined;
    }

    static get IdentityProviderTenantID(): string {
        return _config.IdentityProviderTenantID || undefined;
    }

    static get IdentityProviderBaseUrl(): string {
        return _config.IdentityProviderBaseUrl || undefined;
    }
    static get IdentityIssuer(): string {
        return _config.IdentityIssuer || undefined;
    }

    static get ZestyBaseUrl(): string {
        return _config.ZestyBaseUrl || undefined;
    }

    static get ZestyBaseMediaUrl(): string {
        return _config.ZestyBaseMediaUrl || undefined;
    }

    public static GetConfig(configName) {
        if (_config === undefined) {
            throw Error('Client Configuration has not been loaded');
        } else if (_config[configName] === undefined) {
            throw Error(`Property does not exist: ${configName}`);
        }
        return _config[configName];
    }

    public static GetBasePath() {
        let baseFolder = _config.BaseUrlFolder;
        if (baseFolder)
            baseFolder = PATHS.BaseRoute + baseFolder;
        else
            baseFolder = "";

        return baseFolder;
    }

    public static GetToggleFeatures(){
        return _config["ToggleFeatureComponents"] || [];
    }
}