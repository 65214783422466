import React from 'react';
import MESSAGE from 'helpers/Message';
import OrderHistoryInfo from '../components/orderHistory/OrderHistoryInfo';
import { Text } from '@ftdr/blueprint-components-react';
import OrderDataService from '../api/services/orderDataService';
import { loggedInFusionAuthId } from '../api/services/authService';
import '../components/orderHistory/OrderHistory.css';
import ContentBox from '../pages/ContentBox';
import SearchBar from '../components/searchBar/SearchBar';
import { IconTypes } from '../components/searchBar/SearchUtil';
import { FilterChangeEvent } from '../components/Models/filterModel';
import { FilterOperation, FilterType } from '../constants/dashboardFilters';
import { FilterTagItem } from '../components/Models/filterModel';
import { formatDateToISO8601 } from '../helpers/Utils';
import ListFilter from 'components/orderHistory/ListFilter';
import TagGroup from '../components/tag/TagGroup';
import { Tag, Notification } from '@ftdr/blueprint-components-react';

const MINIMUM_CHARS_FOR_SEARCH: number = 2;

export interface REOrderSearchMetaStatus {
  [key: string]: number;
  active: number;
  cancelled_order: number;
  closing_soon: number;
  expired: number;
  expiring: number;
  payment_due: number;
}

interface IOrderHistoryProps {
  history: any;
}

const OrderStatusMapper = {
  1: 'Active',
  2: 'Cancelled',
  4: 'Expired/Off Market',
  6: 'Paid',
  9: 'Under Contract',
}

interface IOrderHistoryState {
  selectedOrderCriteria: any,
  selectedFilterOption: any,
  searchText: string
  orderHistoryInfo: IOrderHistoryInfo[],
  orderHistoryInfoPristine: IOrderHistoryInfo[],
  filterTagItems: FilterTagItem[];
  totalStatus: Partial<REOrderSearchMetaStatus>;
  activePage: number
}

interface IOrderHistoryInfo {
  order_id: number;
  property_address: string;
  apn: string;
  order_date: Date;
  status: string;
  report_status: string;
  isEscrow: boolean;
  report_type: string;
}

class OrderHistory extends React.Component<IOrderHistoryProps, IOrderHistoryState> {

  constructor(props: any) {
    super(props);

    this.state = {
      selectedFilterOption: '',
      selectedOrderCriteria: '',
      searchText: '',
      orderHistoryInfo: [],
      filterTagItems: [],
      totalStatus: {},
      activePage: 1,
      orderHistoryInfoPristine: []
    }
  }

  public componentDidMount() {

    let userId = loggedInFusionAuthId();

    if (userId != null) {
      OrderDataService.getOrderHistory(userId)
        .then((data) => {
          if (data != undefined) {
            let orderHistoryInfo = data.OrderHistoryItems.map(item => {
              let ordHistInfo: IOrderHistoryInfo = {
                order_id: item.orderId,
                property_address: item.propertyAddress,
                apn: item.apn,
                order_date: item.orderDate,
                status: OrderStatusMapper[item.orderStatus],
                report_status: item.reportStatus,
                isEscrow: item.isEscrow,
                report_type: item.reportType
              }
              return ordHistInfo;
            }
            );

            this.setState({ orderHistoryInfo, orderHistoryInfoPristine: orderHistoryInfo });
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }


  public onOrderCriteriaOptionChange = (selectedOrderCriteria) => {
    this.setState({ selectedOrderCriteria });
  }

  public onFilterOptionChange = (selectedFilterOption) => {
    this.setState({ selectedFilterOption });
  }

  public onSearchTextChange = (searchText) => {
    this.setState({ searchText });
  }

  onSearchFilterChange = (value: string) => {

    let filteredData = [];

    if (value?.length >= MINIMUM_CHARS_FOR_SEARCH) {
      let searchItemToLower = value.toLowerCase();

      filteredData = this.state.orderHistoryInfoPristine.filter(item => {
        let orderIdToLower = item.order_id?.toString()?.toLowerCase();
        let addressToLower = item.property_address?.toString()?.toLowerCase();
        let apn = item.apn?.trim()?.toLowerCase()

        if (orderIdToLower.includes(searchItemToLower) || addressToLower.includes(searchItemToLower) || apn?.includes(searchItemToLower)) {
          return true;
        } else {
          return false
        }
      });

    } else {
      filteredData = this.state.orderHistoryInfoPristine;
    }

    this.setState({ searchText: value, orderHistoryInfo: filteredData });
  };

  onFilterChange(event: FilterChangeEvent, clearFilters = false): boolean {
    let result: { tags: FilterTagItem[], validUpdate: boolean } = { tags: [], validUpdate: false };

    try {
      let { filterTagItems } = this.state;

      if (clearFilters) {
        filterTagItems = [];
      }

      // Handle individual event type
      switch (event.type) {
        case FilterType.STATUS:
          result = this.updateStatusFilter(event, filterTagItems);
          break;
        case FilterType.DATE:
          result = this.updateDateFilter(event, filterTagItems);
          break;
        case FilterType.ORDER_SORT:
          // remove existing orderSort filter if present
          const tags = filterTagItems.filter(tag => tag.type !== FilterType.ORDER_SORT);
          result = { tags: [...tags, event], validUpdate: true };
          break;
        case FilterType.CLEAR_FILTER:
          result = { tags: [], validUpdate: true };
          break;
      }

      if (event.operation === FilterOperation.SEARCH_INPUT) {
        result.validUpdate = true;
      }

      if (result.validUpdate) {
        let statusArr = result.tags.filter(status => status.type == FilterType.STATUS);
        let datesArr = result.tags.filter(date => date.type == FilterType.DATE);
        let filteredOrders = [];

        if (statusArr.length == 0 && datesArr.length == 0) {
          filteredOrders = this.state.orderHistoryInfoPristine;
        }

        if (statusArr.length > 0) {
          let filterByStatus = this.state.orderHistoryInfoPristine.
            filter(item => statusArr.some(sta => sta.payload.status.toLowerCase() == item?.status?.toLowerCase()));
          filteredOrders = filterByStatus;
        }

        if (datesArr.length > 0) {
          let resultsByDate = [];
          let start = datesArr[0].payload.date.start;
          let end = datesArr[0].payload.date.end;
          let filterByDate = [];

          if (filteredOrders.length != 0) {
            filterByDate = filteredOrders;
          } else {
            filterByDate = this.state.orderHistoryInfoPristine;
          }

          filterByDate.forEach(item => {
            let itemDate = new Date(item.order_date);
            if (itemDate >= start && itemDate <= end) {
              resultsByDate.push(item);
            }
          });

          filteredOrders = resultsByDate;
        }

        this.setState({ orderHistoryInfo: filteredOrders, filterTagItems: result.tags });
      }

    } catch (e) {
      console.error('failed to apply filter changes', e);
    }

    return result.validUpdate;
  }

  updateDateFilter(event: FilterChangeEvent, tags: FilterTagItem[]): { tags: FilterTagItem[], validUpdate: boolean } {
    let validUpdate = false;

    const { date } = event.payload;
    const inFilter = tags.find(({ type }) => type === 'date');

    switch (event.operation) {
      case 'add':
        if (date) {
          // We always update the date range if add occurs; replacing the existing one; as there should only be 1 date range filter
          const startDate = formatDateToISO8601(date.start);
          const endDate = formatDateToISO8601(date.end);
          const validDates = Boolean(startDate && endDate);
          if (validDates) {
            tags = [...tags.filter(i => i.type !== 'date'), event]; // Remove the existing tag first, then add
            validUpdate = true;
          }
        }

        break;
      case 'remove':
        if (inFilter) {
          tags = tags.filter(i => i.type !== 'date');
          validUpdate = true;
        } else {
          console.warn('attempted to remove date, but cannot find it', event, tags);
        }
        break;
    }

    return { tags, validUpdate };
  }


  updateStatusFilter(event: FilterChangeEvent, tags: FilterTagItem[]): { tags: FilterTagItem[], validUpdate: boolean } {
    let validUpdate = false;

    const { status } = event.payload;
    const inFilter = tags.find(({ type, payload }) =>
      type === 'status' && payload.status === status
    );

    switch (event.operation) {
      case 'add':
        if (!inFilter) {
          tags = [...tags, event];
          validUpdate = true;
        }
        break;
      case 'remove':
        if (inFilter) {
          tags = tags.filter(({ type, payload }) =>
            !(type === 'status' && payload.status === status)
          );
          validUpdate = true;
        }
        break;
    }

    return { tags, validUpdate };
  }


  addFilter = (label: string,
    event: FilterChangeEvent = {
      label,
      operation: FilterOperation.ADD,
      payload: {},
      type: FilterType.CLEAR_FILTER
    },
    clearFilters = false
  ): void => {
    if (event.type && event.payload) {
      console.log(event);
      this.onFilterChange(event, clearFilters);
      this.setState({ activePage: 1 });
    }
  };


  getListFiltering = () => {
    return (
      <ListFilter
        id="orders-container--filter-dropdowns"
        addFilter={this.addFilter}
        activeFilters={this.state.filterTagItems}
        statusAlerts={this.state.totalStatus}
      />

    );
  };

  /** Removes a filter from the list */
  removeFilter = (tagItem: FilterTagItem): void => {
    this.onFilterChange({ ...tagItem, operation: FilterOperation.REMOVE });
    this.setState({ activePage: 1 });
  }

  filtersForDisplay = (filters: FilterTagItem[]): FilterTagItem[] => {
    const excludeFilterTypes = ['orderSort'];
    return filters.filter(tag => !excludeFilterTypes.includes(tag.type));
  }

  render() {
    return (

      <div>
        <div className="flex w-full items-center flex-col">
          <p className='order-history-title'>
            {MESSAGE.order_history_header_text}
          </p>

        </div>

        <div className="order-history-container order-form" >
          <ContentBox
            title="Order History"
            //@ts-ignore
            contentTitle={MESSAGE.order_history_sub_header_text}
            leftContent={<div className="w-48 min-w-48 mr-4">
              <Text variant="heading-06" className="my-2">Filter Orders</Text>
              {this.getListFiltering()}
            </div>}
          >
            <div className='bds-ahs-web-app'>
              <div className="mb-4 mt-3">
                <TagGroup>
                  <div>
                    {this.filtersForDisplay(this.state.filterTagItems).length > 0 &&
                      <div className="float-left">Filtered by:</div>}
                    <div className="items-center flex flex-wrap">
                      {this.filtersForDisplay(this.state.filterTagItems).map((item, idx) => (
                        <Tag
                          id={`tag-${idx}`}
                          color="interactive"
                          removable
                          onRemove={() => {
                            this.removeFilter(item);
                          }}
                          className="ml-4"
                        >
                          {item.label}
                        </Tag>
                      ))}
                    </div>
                  </div>
                </TagGroup>
              </div>
              <div className="flex-1 mb-4">
                <SearchBar
                  searchText={this.state.searchText}
                  onChange={(value) => this.onSearchFilterChange(value)}
                  icon={IconTypes.Filter}
                  placeHolder={MESSAGE.filter_by_number_name_address}
                  showClearButton={true}
                  outerCSS='p-3'
                >
                </SearchBar>
              </div>
              <OrderHistoryInfo data={this.state.orderHistoryInfo} />
            </div>
          </ContentBox>
        </div>
      </div>
    );
  }
}

export default OrderHistory;