import React from 'react';
import './logoutModal.css';
import MESSAGE from "helpers/Message";
import ConfigHelper from "helpers/ConfigHelper";

interface IIdleTimeModalProps {
    onYes: any;
    onNo : any;
}

const IdleTimeModal: React.FC<IIdleTimeModalProps> = ({
    onYes,
    onNo
}) => {
    const idletime_expires_time = ConfigHelper.GetConfig("IdleTimeout_Expires_Time_InMilliseconds");
    const idletime_warning_time = ConfigHelper.GetConfig("IdleTimeout_Warning_Time_InMilliseconds");
    const idletime = parseInt(idletime_expires_time) - parseInt(idletime_warning_time);
    const idleTime_in_mins = idletime / 60000;
    const timeout_message = `You have been inactive for ${idleTime_in_mins} mins. Do you want to continue the session?`;

    return (
        <div className="logout-details">            
            <h4><b>{MESSAGE.idletimeout_message_title}</b></h4>
            <hr className="horizontal-divider" />
            <div className="address-text">
                <div >
                    <p>{timeout_message}</p>
                </div>
            </div>
            <div>
            <ul className="list-inline text-center">
                    <li className="list-inline-item  text-left">
                        <button
                            type="submit"
                            className="btn blue-btn medium"
                            onClick={onYes}
                        >
                            {"Yes"}
                        </button>
                        
                    </li>
                    <li className="list-inline-item  text-right">
                     
                        <button
                            type="submit"
                            className="btn blue-btn medium"
                            onClick={onNo}
                        >
                            {"No"}
                        </button>
                    </li>
                    </ul>
               
            </div>
        </div>
    );
}

export default IdleTimeModal;