import React, {useEffect, useState} from "react";
import HeaderImageService from "../../api/services/headerImageService";

interface HeaderImgPros {
    desc: string;
}

const HeaderImg: React.FC<HeaderImgPros> = ({
    desc
}) => {
    const [img, setImg] = useState("");    
    useEffect(() => {
        HeaderImageService().then((responseData) => {
           setImg(responseData.data[0].logo);
        });
    }, [img]);

    return <img src={img} alt={desc} className="logo-img"/>
};

export default HeaderImg;
