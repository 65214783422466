import React from "react";
import LabelField from "./LabelField";

interface RadioFieldProps {
  className: string;
  name: string;
  id?: string;
  value: string;
  label: string;
  isChecked?: boolean;
  handleChange?: any;
  isSubmitted?: boolean;
  handleClick?: any;
  defaultChecked?: boolean;
  prefixText?: string;
}

const RadioField: React.FC<RadioFieldProps> = ({
  className,
  id,
  name,
  value,
  label,
  isChecked,
  handleChange,
  isSubmitted,
  handleClick,
  defaultChecked,
  prefixText
}) => {
  return (
    <>
      <input
        type="radio"
        className={className}
        id={id}
        name={name}
        value={value}
        onChange={handleChange}
        onClick={handleClick}
        checked={isChecked}
        defaultChecked={defaultChecked}
      />
      {label && (
        <LabelField
          htmlFor={id}
          className="custom-control-label"
          labelText={label}
          prefixText={prefixText}
        />
      )}
    </>
  );
};

export default RadioField;
