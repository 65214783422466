import axios, { AxiosResponse } from "axios";
import ConfigHelper from "helpers/ConfigHelper";
import ApiHelper from "../ApiHelper";
import {TrackGAEvent} from "../../components/gaTracking/GATracking";

interface CompanyAddressModel {
  street: string;
  city: string;
  state: string;
  zip: string;
}

interface UserDataServiceModel {
  accountType: string;
  firstName: string;
  name: string;
  companyName: string;
  phoneNumber: string;
  email: string;
  companyAddress: CompanyAddressModel;
}

export default class UserDataService {
  public static GetUserDetails(): Promise<UserDataServiceModel> {
    var config = ApiHelper.GetConfig();

    return axios
      .get(ConfigHelper.ApiBaseUrl + "userprofile", config)
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch(() => {
        TrackGAEvent("service", "failed", "user data service failed or guest user");
        return undefined;
      });
  }
}
