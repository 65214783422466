import React from "react";
import HeaderTabType from "./../../enums/HeaderTabType";
import {Link, useLocation} from "react-router-dom";

interface HeaderTabProps {
    href: string;
    headerTabType: HeaderTabType;
}

const HeaderTab = (props: HeaderTabProps) => {
    const location = useLocation();

    function isCurrent() {
        return props.href === location.pathname;
    }

    return <li className={isCurrent() ? "current" : ""}>
        <Link to={props.href}>{props.headerTabType}</Link>
    </li>
};

export default HeaderTab;