import React from "react";
import LabelField from "./LabelField";
import {
  ValidatePhoneNumber,
  ValidateEmail,
  ValidateZipCode
} from "helpers/Validation";
import ERROR_MESSAGES from "helpers/ErrorMessages";
import './FormFields.css';


interface InputFieldProps {
  placeholder?: string;
  name: string;
  value?: string;
  required?: boolean;
  readOnly?: boolean;
  label?: string;
  type: string;
  className?: string;
  defaultValue?: string;
  showOptionalKey?: boolean;
  showPhoneOrEmailNote?: boolean;
  listName?: string;
  isSubmitted?: boolean;
  preValidateField?: boolean;
  validateZip?: boolean;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  maxLength?: number;
  refr?: any;
}

interface InputStateModel {
  isValidPhoneNumber: boolean;
  isValidEmail: boolean;
  isValidZip: boolean;
  preValidated: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
  className,
  type,
  placeholder,
  name,
  value,
  required,
  readOnly,
  label,
  handleChange,
  defaultValue,
  showOptionalKey,
  showPhoneOrEmailNote,
  listName,
  handleBlur,
  isSubmitted,
  preValidateField,
  validateZip,
  maxLength,
  refr
}) => {
  const [inputState, setInputState] = React.useState<InputStateModel>({
    isValidPhoneNumber: true,
    isValidEmail: true,
    isValidZip: true,
    preValidated: false
  });

  if (preValidateField && !inputState.preValidated && value) {
    if (type === "tel") {
      const isValidPhoneNumber = ValidatePhoneNumber(value);
      setInputState({ ...inputState, isValidPhoneNumber, preValidated: true });
    } else if (type === "email") {
      const isValidEmail = ValidateEmail(value);
      setInputState({ ...inputState, isValidEmail, preValidated: true });
    } else if (validateZip) {
      const isValidZip = ValidateZipCode(value);
      setInputState({ ...inputState, isValidZip, preValidated: true });
    }
  }

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (type === "tel") {
      const isValidPhoneNumber = ValidatePhoneNumber(value);
      setInputState({ ...inputState, isValidPhoneNumber });
    } else if (type === "email") {
      const isValidEmail = ValidateEmail(value);
      setInputState({ ...inputState, isValidEmail });
    } else if (validateZip) {
      const isValidZip = ValidateZipCode(value);
      setInputState({ ...inputState, isValidZip, preValidated: true });
    }

    if (handleBlur) {
      handleBlur(event);
    }
  };

  const renderErrorMessages = () => {
    let errorMsg = "";
    if (isSubmitted && required && !value)
      errorMsg = label ? `${label} is required.` : "This field is required.";
    else if (!inputState.isValidPhoneNumber && value)
      errorMsg = ERROR_MESSAGES.INVALID_PHONE_NUMBER;
    else if (!inputState.isValidEmail && value)
      errorMsg = ERROR_MESSAGES.INVALID_EMAIL;
    else if (!inputState.isValidZip && value)
      errorMsg = ERROR_MESSAGES.INVALID_ZIP;

    return errorMsg && <span className="text-danger">{errorMsg}</span>;
  };

  return (
    <div className="form-group">
      {label && (
        <>
          <LabelField
            htmlFor={name}
            className={"label-title " + (required ? "req" : "")}
            labelText={label}
          />
          {showOptionalKey && (
            <span className="text-muted font-weight-normal">
              &nbsp;(optional)
            </span>
          )}
          {showPhoneOrEmailNote && (
              <span className="text-muted font-weight-normal">
              &nbsp;(provide either phone or email)
            </span>
          )}
        </>
      )}
      <input
        type={type}
        className={className}
        placeholder={placeholder}
        id={name}
        name={name}
        defaultValue={defaultValue}
        required={required}
        readOnly={readOnly}
        onChange={handleChange}
        value={value}
        list={listName}
        onBlur={onBlurHandler}
        maxLength={maxLength}
        ref={refr}
      />
      {renderErrorMessages()}
    </div>
  );
};

export default InputField;
