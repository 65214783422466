
import React, { useEffect, useState } from "react";
import "./PromotionalBanner.css";
import HeaderImageService from '../../api/services/headerImageService';

interface PromotionalBannerProps { }

const PromotionalBanner: React.FC<PromotionalBannerProps> = ({
}) => {
  const [promoMessage, setPromoMessage] = useState('');
  useEffect(() => {
    HeaderImageService().then(response => {
      if (response.data.length > 0) {
        setPromoMessage(response.data[1].promo_banner);
      }
    });
  }, [promoMessage])

  return (
    <div className="promotional-banner-text">
      {promoMessage}
    </div>
  )
}


export default PromotionalBanner;
