import {getJWT} from "./services/authService";

export default class ApiHelper{
    public static GetConfig(data:any = null) {

        return {
            data: data? data : {},
            //Weird Axios issue, Content Type won't get sent unless data is included.  Our API requires Content Type to be set
            headers: {
                'Content-Type':'application/json',
                Accept:"*/*",
                Authorization: "Bearer " + getJWT()
            }
        };
    }
}
