export const ValidatePhoneNumber = (value) => {
  const patt = new RegExp(/^\([2-9]\d{2}\)[\s](?!555)[2-9](?!11)\d{2}[-]\d{4}$/);
  return patt.test(value)
}

export const ValidateEmail = (value) => {
  const patt = new RegExp(/\S+@\S+\.\S+/);
  return patt.test(value)
};

export const ValidateZipCode = (value) => {
  const patt = new RegExp(/^\d{5}$/);
  return patt.test(value)
};

export const IsFormValid = (values) => {
  let isValid = true;
  for (const fieldName of Object.keys(values)) {
    const field = values[fieldName];

    if (field["required"] && !field["value"]) {
      isValid = false;
      break;
    }

    if (field["value"] && field["type"]) {
      if (field["type"] === "tel" && !ValidatePhoneNumber(field["value"])) {
        isValid = false;
        break;
      }
      if (field["type"] === "email" && !ValidateEmail(field["value"])) {
        isValid = false;
        break;
      }
    }
  }

  return isValid;
}

export const scrollIntoView = (values) => {
  for (const fieldName of Object.keys(values)) {
    const field = values[fieldName];
    let ref = field["refr"];

    if (field["required"] && !field["value"] && ref) {
      navigateAndFocus(ref);
      break;
    }

    if (field["value"] && field["type"]) {
      if (field["type"] === "tel" && !ValidatePhoneNumber(field["value"]) && ref) {
        navigateAndFocus(ref);
        break;
      }
      if (field["type"] === "email" && !ValidateEmail(field["value"]) && ref) {
        navigateAndFocus(ref);
        break;
      }
    }
  }
}

const navigateAndFocus = (ref) => {
  ref.current.focus();
  ref.current.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'start',
  });
} 
