import React, { useState } from 'react';
import { IconFilter, IconSearch, Input } from '@ftdr/blueprint-components-react';
import {IconTypes} from './SearchUtil';

interface SearchBarProps {
    searchText: string;
    onChange: (value: string) => void;
    placeHolder: string;
    showClearButton: boolean;
    idPrefix?:string;
    icon: IconTypes;
    outerCSS?: string;
  }

const SearchBar: React.FC<SearchBarProps> = props => {
    const [showAutocomplete, setShowAutocomplete] = useState(false);

    const handleInputChange = e => {
        // As per discussion with mallory we dont need tooltips for now
        // for more details please check comments in ARE-3775
        // setShowAutocomplete(e.target.value !== '')
        const {value} = e.target;
        props.onChange(value);
    };

    function getIcon () {
        switch (props.icon) {
            case IconTypes.Filter:
                return <IconFilter />;
            case IconTypes.Search:
                return <IconSearch />;
        }
    }

    return (    
        <div className="w-full">
          <Input
            label='Search Bar Filter'
            value={props.searchText}
            showClearButton={props.showClearButton}
            onChange={handleInputChange}
            placeholder={props.placeHolder}
            startEnhancer={getIcon()}
            id={`${props.idPrefix}search_input`}
            autoComplete='off'
          />
        </div>
    );
};

export default SearchBar;
