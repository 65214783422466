import React from 'react';
import MESSAGE from 'helpers/Message';
import { Text } from '@ftdr/blueprint-components-react';
import { loggedInFusionAuthId } from '../api/services/authService';
import TagGroup from '../components/tag/TagGroup';
import { Tag } from '@ftdr/blueprint-components-react';

interface IDashboardState {
  
}

interface IDashboardProps {
}

class Dashboard extends React.Component<IDashboardState, IDashboardProps> {

  constructor(props: any) {
    super(props);

    this.state = {
     
    }
  }

  public componentDidMount() {

    
  }


  render() {
    return (
      <div className="mt-15 ml-3" >
       Admin Dashboard
      </div>
    );
  }
}

export default Dashboard;