import React from "react";
import "./collectAddressDetails.css";
import MESSAGE from "helpers/Message";
import FullAddressInput from "../fullAddressInput/fullAddressInput";

interface CollectAddressDetailsProps {
    defaultStreetAddress: string;
    defaultUnitNumber: string;
    defaultCity: string;
    defaultState: string;
    defaultZip: string;
    onUseAddress: any;
    onClose?: Function;
}

interface CollectAddressDetailsState {
    streetAddress: string;
    unitNumber: string;
    city: string;
    state: string;
    zipCode: string;
    isAddressRequired: boolean;
    isCityRequired: boolean;
    isZipRequired: boolean;
}

class CollectAddressDetails extends React.Component<
    CollectAddressDetailsProps,
    CollectAddressDetailsState
    > {
    constructor(props: CollectAddressDetailsProps) {
        super(props);
        this.state = {
            streetAddress: this.props.defaultStreetAddress,
            unitNumber: this.props.defaultUnitNumber,
            city: this.props.defaultCity,
            state: this.props.defaultState,
            zipCode:  this.props.defaultZip,
            isAddressRequired: false,
            isCityRequired: false,
            isZipRequired: false
        };
    }

    onChangeHandler (event, formName) {
        const stateObject = this.state;
        stateObject[formName] = event.target.value;
        this.setState(stateObject);
    }

    onSubmit = () => {
        if (this.state.streetAddress && this.state.city && this.state.zipCode) {
            this.props.onUseAddress(this.state)
        }

        this.setState({
            isAddressRequired: !this.state.streetAddress,
            isCityRequired: !this.state.city,
            isZipRequired: !this.state.zipCode
        });
    }

    onClose = () => {
        if (this.props.onClose)
            this.props.onClose();
    }

    render() {
        return (
            <div className="collect-address-apn-details">
               <span onClick={this.onClose} className="close">&times;</span>
                <h4><b>{MESSAGE.update_address}</b></h4>
                <p className="disclaimer">{MESSAGE.accurate_info_message}</p>

                <FullAddressInput
                    streetAddress={this.state.streetAddress}
                    unitNumber={this.state.unitNumber}
                    zipCode={this.state.zipCode}
                    city={this.state.city}

                    isStreetRequired={this.state.isAddressRequired}
                    isCityRequired={this.state.isCityRequired}
                    isZipRequired={this.state.isZipRequired}

                    onStreetAddressChange={event => {
                        this.onChangeHandler(event, "streetAddress")
                    }}
                    onUnitChanged={event => {
                        this.onChangeHandler(event, "unitNumber")
                    }}
                    onCityChange={event => {
                        this.onChangeHandler(event, "city")
                    }}
                    onZipCodeChange={event => {
                        this.onChangeHandler(event, "zipCode")
                    }}

                    onClose={this.onClose}
                />

                <div>
                    <ul className="list-inline text-right">
                        <li className="list-inline-item">
                            <button
                                type="submit"
                                className="btn blue-btn"
                                onClick={this.onSubmit}
                            >
                                {"Continue"}
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default CollectAddressDetails;