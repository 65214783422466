export default abstract class LocalStorageHelper {
    static NHD_ADDRESS = "nhdSelectedAddress";
    static NHD_COUNTY = "nhdSelectedCounty";
    static NHD_APN = "nhdSelectedAPN";
    static NHD_FIRSTNAME = "nhdFirstName";
    static NHD_LASTNAME = "nhdLastName";
    static NHD_USERDETAILS = "nhdUserDetails";
    static NHD_ORDER_ID = "nhdOrderId";

    static ClearLocalStorage(){
        localStorage.removeItem(this.NHD_ADDRESS);
        localStorage.removeItem(this.NHD_COUNTY);
        localStorage.removeItem(this.NHD_APN);
        localStorage.removeItem(this.NHD_ORDER_ID);
    }
    static ClearUserData(){
        localStorage.removeItem(this.NHD_FIRSTNAME);
        localStorage.removeItem(this.NHD_LASTNAME);
        localStorage.removeItem(this.NHD_USERDETAILS);
    }
}