
import React, { useEffect, useState } from "react";
import "./PromotionalBanner.css";
import HeaderImageService from '../../api/services/headerImageService';

const PromotionalHeaderBanner: React.FC<any> = ({
}) => {
  const [promoMessage, setPromoMessage] = useState('');
  useEffect(() => {
    HeaderImageService().then(response => {
      if (response.data.length > 1) {
        setPromoMessage(response.data[2].promo_banner_header_text);
      }
    })
  }, [promoMessage])

  return (
    <div className="promotional-banner-text">
      <span dangerouslySetInnerHTML={{ __html: promoMessage }} />
    </div>
  )
}

export default PromotionalHeaderBanner;
