import React from "react";
import { Banner, LandingBody, OrderReportCard } from "components";
import MESSAGE from "helpers/Message";
import { OrderReportCardModel } from "components/Models";
import PromotionalBanner from "../components/PromotionalBanner/PromotionalBanner";
import "../components/PromotionalBanner/PromotionalBanner.css";

interface IProps {
  history: any;
}
class LandingPage extends React.Component<IProps> {
  defaultOrderReportDetails: OrderReportCardModel = {
    order_report_card_title: MESSAGE.order_report_card_title,
    use_address: MESSAGE.use_address,
    use_apn: MESSAGE.use_apn,
    search_by_property_address: MESSAGE.search_by_property_address,
    search_by_property_apn: MESSAGE.enter_apn,
    continue_button_text: MESSAGE.continue_button_text,
    get_started_button_text: MESSAGE.get_started_button_text,
    address: MESSAGE.address,
    apn: MESSAGE.apn,
    search_by_property_address_zip: MESSAGE.zip_code
    
  };

  render() {
    return (
      <div className="LandingPage pre-bds">
        <Banner
          banner_info={MESSAGE.banner_info}
          banner_title={MESSAGE.banner_title}
        />
        <OrderReportCard
          defaultFields={this.defaultOrderReportDetails}
        />
        <LandingBody />
        <div className="promotional-banner-text">
          <PromotionalBanner />
        </div>
      </div>
    );
  }
}
export default LandingPage;
