import React from 'react';
import './logoutModal.css';
import MESSAGE from "helpers/Message";
import ConfigHelper from "helpers/ConfigHelper";

interface ILogoutModalProps {
    onOK: any;
}

const LogoutModal: React.FC<ILogoutModalProps> = ({
    onOK
}) => {
    const logout_warning_time = ConfigHelper.GetConfig("Logout_Warning_Time_InMilliseconds");
    const logout_warning_time_In_Mins = parseInt(logout_warning_time) / 60000;
    const timeout_message = `You are going to be logged out in ${logout_warning_time_In_Mins} mins`;

    return (
        <div className="logout-details">
            <h4><b>{MESSAGE.logout_message_title}</b></h4>
            <hr className="horizontal-divider" />
            <div className="address-text">
                <div >
                    <p>{timeout_message}</p>
                </div>
            </div>
            <div>
                <ul className="list-inline text-right">
                    <li className="list-inline-item">
                        <button
                            type="submit"
                            className="btn blue-btn"
                            onClick={onOK}
                        >
                            {"OK"}
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
}


export default LogoutModal;