import React from 'react';
import { classNames } from '../../helpers/Utils';

const TagGroup = props => {
    const renderChildren = () => {
        return React.Children.map(props.children, (child, idx) => {
            return <div className="p-1">{React.cloneElement(child)}</div>;
        });
    };

    return (
      <div
        className={classNames([
                'flex flex-wrap items-center -m-1',
                props.className || '',
            ])}
      >
        {renderChildren()}
      </div>
    );
};

export default TagGroup;
