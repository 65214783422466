import React from "react";
import IframeResizer from "iframe-resizer-react";
import ConfigHelper from "helpers/ConfigHelper";


const Footer = () =>
{
    let footerUrl = `${ConfigHelper.ZestyBaseUrl}/footer/`;
    return <IframeResizer
        src={footerUrl}
        title={"Footer"}
        className={'zesty-iframe'}
        heightCalculationMethod={'lowestElement'}
    />
}

    

export default Footer;