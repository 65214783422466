import React from "react";
import { InputField, LabelField, Dropdown, CheckBox } from "components";
import { RealEstateAgentModel, FormFieldModel } from "components/Models/";
import "./RealEstateAgentInfo.css";
import RealEstateAgentDetails from "./Models/RealEstateAgentDetails";

interface RealEstateAgentInfoProps {
  defaultFields: RealEstateAgentModel;
  defaultValues?: RealEstateAgentDetails;
  fieldValues: FormFieldModel;
  isSubmitted: boolean;
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface RealEstateAgentInfoState {
  agentInfo?: RealEstateAgentDetails;
  agentInfoValues: RealEstateAgentModel;
  isFSBOChecked : boolean;
}

class RealEstateAgentInfo extends React.Component<
  RealEstateAgentInfoProps,
  FormFieldModel
> {
   constructor(props: RealEstateAgentInfoProps) {
    super(props);

    this.state = {
      agentInfo: this.props.defaultValues,
      agentInfoValues : this.props.fieldValues
    };
  }

  static getDerivedStateFromProps(props: RealEstateAgentInfoProps) {
    return { agentInfo: props.defaultValues, agentInfoValues: props.fieldValues };
  }

  private onSaleByOwnerChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let isChecked = e.target.checked;
    const { agentInfoValues } = this.state;    

    agentInfoValues.agentFirstName.required = !isChecked;
    agentInfoValues.agentLastName.required = !isChecked;
    agentInfoValues.agentCompany.required = !isChecked;
    agentInfoValues.agentPhoneNumber.required = !isChecked;
    agentInfoValues.email1.required = !isChecked;
    agentInfoValues.agentCompanyState.required = !isChecked;
    agentInfoValues.agentCompanyState.disabled = isChecked;

    this.setState({ agentInfoValues, isFSBOChecked: isChecked });
  }

  render() {
    const { fieldValues } = this.props;

    return (
      <fieldset>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <legend className="col-lg-9 col-md-9 col-sm-12 col-xs-12 p-0 m-0">
              <LabelField
                className="form-title"
                labelText={this.props.defaultFields.real_estate_agent}
              />
            </legend>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 text-center pt-10">
            <input type="checkbox" name="chkSaleByOwner" className="checkBoxSaleByOwner" value="checked" 
                onChange={(e) => { this.onSaleByOwnerChangeHandler(e) }}></input>
            <label className='fsboText'>{this.props.defaultFields.sale_by_owner}</label>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <InputField
              {...this.state.agentInfoValues.agentFirstName}
              placeholder={this.props.defaultFields.enter_first_name}
              label={this.props.defaultFields.agent_name_label}
              className="form-control form-placeholder"
              handleChange={this.props.onChangeHandler}
              isSubmitted={this.props.isSubmitted}
              readOnly={this.state.isFSBOChecked}
              maxLength={50}
              refr={this.state.agentInfoValues.agentFirstName.refr}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-30">
            <InputField
              {...this.state.agentInfoValues.agentLastName}
              placeholder={this.props.defaultFields.enter_last_name}
              className="form-control form-placeholder"
              handleChange={this.props.onChangeHandler}
              isSubmitted={this.props.isSubmitted}
              readOnly={this.state.isFSBOChecked}
              maxLength={50}
              refr={this.state.agentInfoValues.agentLastName.refr}
            />
          </div>
        </div>

        <InputField
          {...this.state.agentInfoValues.agentCompany}
          placeholder={this.props.defaultFields.enter_company_name}
          label={this.props.defaultFields.agent_company}
          className="form-control form-placeholder"
          handleChange={this.props.onChangeHandler}
          readOnly={this.state.isFSBOChecked}
          isSubmitted={this.props.isSubmitted}
          maxLength={100}
          refr={this.state.agentInfoValues.agentCompany.refr}
        />

        <InputField
          {...this.state.agentInfoValues.agentCompanyAddress}
          placeholder={this.props.defaultFields.enter_agent_company_street_address}
          label={this.props.defaultFields.agent_company_address}
          className="form-control form-placeholder"
          handleChange={this.props.onChangeHandler}
          readOnly={this.state.isFSBOChecked}
          isSubmitted={this.props.isSubmitted}
          maxLength={200}
        />

        <div className="row">
          <div className="col-5">
            <InputField
              {...this.state.agentInfoValues.agentCompanyCity}
              placeholder={this.props.defaultFields.enter_agent_company_city}
              label={this.props.defaultFields.agent_company_city}
              isSubmitted={this.props.isSubmitted}
              className="form-control form-placeholder"
              handleChange={this.props.onChangeHandler}
              readOnly={this.state.isFSBOChecked}
              maxLength={50}
            />

          </div>
          <div className="col-3">
            <Dropdown
              {...this.state.agentInfoValues.agentCompanyState}
              className="form-control"
              label={this.props.defaultFields.agent_company_state}
              options={this.props.defaultFields.agent_company_state_name}
              handleChange={this.props.onChangeHandler}
              readOnly={this.state.isFSBOChecked}
              isSubmitted={this.props.isSubmitted}
            />
          </div>
          <div className="col-4">
            <InputField
              {...this.state.agentInfoValues.agentCompanyZipCode}
              placeholder={this.props.defaultFields.enter_agent_company_zip}
              label={this.props.defaultFields.agent_company_zip_code}
              className="form-control form-placeholder"
              handleChange={this.props.onChangeHandler}
              isSubmitted={this.props.isSubmitted}
              readOnly={this.state.isFSBOChecked}
              validateZip={true}
              maxLength={10}
            />
          </div>
        </div>

        <InputField
          {...this.state.agentInfoValues.agentPhoneNumber}
          placeholder={this.props.defaultFields.enter_phone_number}
          label={this.props.defaultFields.agent_phone_number}
          className="form-control form-placeholder"
          handleChange={this.props.onChangeHandler}
          isSubmitted={this.props.isSubmitted}
          readOnly={this.state.isFSBOChecked}
          maxLength={20}
          refr={this.state.agentInfoValues.agentPhoneNumber.refr}
        />

        <InputField
          {...this.state.agentInfoValues.email1}
          placeholder={this.props.defaultFields.enter_email_address}
          label={this.props.defaultFields.agent_email}
          className="form-control form-placeholder"
          handleChange={this.props.onChangeHandler}
          isSubmitted={this.props.isSubmitted}
          readOnly={this.state.isFSBOChecked}
          maxLength={100}
          refr={this.state.agentInfoValues.email1.refr}
        />       
      </fieldset>
    );
  }
}
export default RealEstateAgentInfo;
