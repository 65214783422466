import React from "react";
import "./Banner.css";
import BackgroundImage from "assets/img/banner_medium.jpg";

interface BannerProps {
  banner_title: string;
  banner_info: string;
}

const Banner: React.FC<BannerProps> = ({ banner_title, banner_info }) => {
  return (
    <div
      className="bg-img"
      style={{ backgroundImage: "url(" + BackgroundImage + ")" }}
    >
      <h1 className="m-0 font-weight-bold">{banner_title}</h1>
      <p>{banner_info}</p>
    </div>
  );
};

export default Banner;
