import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { login } from "./api/services/authService";
import ConfigHelper from "./helpers/ConfigHelper";
import { PortalSettingsContextProvider } from '@ftdr/blueprint-components-react';

ConfigHelper.LoadConfig().then(() => {
    login().then(() => {
        ReactDOM.render(<PortalSettingsContextProvider disabled={true}>
            <App />
        </PortalSettingsContextProvider>, document.getElementById("root"));
    })
});