import React from "react";
import LabelField from "./LabelField";
import Option from "../../helpers/Option";

interface DropdownProps {
    label?: string;
    name: string;
    className: string;
    defaultOption?: any;
    required: boolean;
    options: Option[];
    handleChange?: any;
}

const DropdownWithValues: React.FC<DropdownProps> = ({
                                               label,
                                               name,
                                               className,
                                               defaultOption,
                                               required,
                                               options,
                                               handleChange,
                                           }) => {
    return (
        <>
            {label && (
                <LabelField
                    htmlFor={name}
                    className={"label-title " + (required ? "req" : "")}
                    labelText={label}
                />
            )}
            <select className={className} id={name} onChange={handleChange} defaultValue={defaultOption}>
                {defaultOption && <option disabled>{defaultOption}</option>}
                {options
                    ? options.map(item => {
                        return (
                            <option key={item.getValue()} value={item.getValue()}>
                                {item.getText()}
                            </option>
                        );
                    })
                    : ""}
            </select>
        </>
    );
};

export default DropdownWithValues;
