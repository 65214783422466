import Axios from "axios";


/**
 * Base request config.
 */
const baseHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json"
};

const plainHTTPClient = Axios.create();

/**
 * A HTTP POST caller that handles JSON.
 *
 * @param endpoint The API Endpoint to be called.
 * @param requestData Request data to be POSTed.
 * @param contentTypeOverride Overrides the Content-Type header
 * @param usePlainHTTPClient Force use a plain (non token-handling) http client
 */
export const post = async (
  endpoint: string,
  requestData: any,
  contentTypeOverride?: string,
  usePlainHTTPClient: boolean = false
) => {
  const config = {
    headers: getHeaders(contentTypeOverride)
  };
  const httpClient = plainHTTPClient;
  const response = await httpClient.post(endpoint, requestData, config);
  return response.data;
};

/**
 * A HTTP GET caller that handles JSON.
 *
 * @param endpoint The API Endpoint to be called.
 * @param contentTypeOverride Overrides the Content-Type header
 * @param usePlainHTTPClient Force use a plain (non token-handling) http client
 */
export const get = async (
  endpoint: string,
  contentTypeOverride?: string,
  usePlainHTTPClient: boolean = false
) => {
  const config = {
    headers: getHeaders(contentTypeOverride)
  };
  const httpClient = plainHTTPClient;
  const response = await httpClient.get(endpoint, config);
  return response.data;
};

const getHeaders = (contentTypeOverride?: string) => {
  const headers = { ...baseHeaders };

  if (contentTypeOverride) {
    headers["Content-Type"] = contentTypeOverride;
  }
  return headers;
};
