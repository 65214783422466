import React from 'react';
import IframeResizer from 'iframe-resizer-react';
import ConfigHelper from "helpers/ConfigHelper";


const Resources = () => 
{
    let resourcesUrl = `${ConfigHelper.ZestyBaseUrl}/resources/`;
    return <IframeResizer
            src={resourcesUrl}
            title={"Resources"}
            className={'zesty-iframe'}
            heightCalculationMethod={'lowestElement'}
        />
}
    
export default Resources;
