import React from "react";
import LabelField from "./LabelField";

interface CheckBoxProps {
  value?: string;
  name: string;
  id?: string;
  label?: string;
  readOnly?: boolean;
  className?: string;
  disabled?: boolean;
  checked?: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckBox: React.FC<CheckBoxProps> = ({
  className,
  readOnly,
  id,
  name,
  value,
  label,
  disabled,
  checked,
  handleChange
}) => {
  return (
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        className={className}
        id={id}
        value={value}
        name={name}
        readOnly={readOnly}
        disabled={disabled}
        checked={checked}
        onChange={handleChange}
      />
      <LabelField
        htmlFor={id}
        className="custom-control-label"
        labelText={label}
      />
    </div>
  );
};

export default CheckBox;
