import React, {ChangeEvent} from "react";

interface FullAddressInputProps {
    streetAddress: string;
    unitNumber: string;
    city: string;
    zipCode: string;

    isStreetRequired: boolean;
    isCityRequired: boolean;
    isZipRequired: boolean;

    onStreetAddressChange(e: ChangeEvent): void;
    onUnitChanged(e: ChangeEvent): void;
    onCityChange(e: ChangeEvent): void;
    onZipCodeChange(e: ChangeEvent): void;

    onClose(): void;
}

const FullAddressInput = (props: FullAddressInputProps) => {
    return (
        <>
            <div className="row">
                <div className="col-8">
                    <div className="form-group">
                        <label htmlFor="collect-street-address" className="label-title req">
                            <b>Address</b>
                        </label>
                        <input
                            type="text"
                            id="collect-street-address"
                            name="streetAddress"
                            readOnly={false}
                            placeholder={"Enter Address"}
                            className={"form-control form-placeholder"}
                            value={props.streetAddress}
                            onChange={props.onStreetAddressChange}
                        />
                        {
                            props.isStreetRequired && <p className="text-danger">Address is required</p>
                        }
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <label htmlFor="collect-unit" className="label-title"> <b>Unit #</b><span className="optional"> (optional)</span> </label>
                        <input
                            type="text"
                            id="collect-unit"
                            name="unitNumber"
                            maxLength={80}
                            readOnly={false}
                            placeholder={"Enter #"}
                            className={"form-control form-placeholder"}
                            value={props.unitNumber}
                            onChange={props.onUnitChanged}
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <div className="form-group">
                        <label htmlFor="collect-street-address" className="label-title req">
                            <b>City</b>
                        </label>
                        <input
                            type="text"
                            name="city"
                            readOnly={false}
                            placeholder={"Enter City"}
                            className={"form-control form-placeholder"}
                            value={props.city}
                            onChange={props.onCityChange}
                        />
                        {
                            props.isCityRequired && <p className="text-danger">City is required</p>
                        }
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group">
                        <label htmlFor="collect-street-address" className="label-title req">
                            <b>State</b>
                        </label>
                        <input
                            type="text"
                            name="state"
                            required={true}
                            readOnly={true}
                            className={"form-control"}
                            value={'CA'}
                        />
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <label htmlFor="collect-street-address" className="label-title req">
                            <b>Zip</b>
                        </label>
                        <input
                            type="text"
                            name="zipCode"
                            readOnly={false}
                            placeholder={"Enter ZIP Code"}
                            className={"form-control form-placeholder"}
                            value={props.zipCode}
                            onChange={props.onZipCodeChange}
                        />
                        {
                            props.isZipRequired && <p className="text-danger">ZIP Code is required</p>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default FullAddressInput;
