import React, { Component } from 'react';
import Button from "../components/formFields/Button";

interface State {
    hasError: boolean
    error: string
    trace: string
}

/** 
 * On javascript error that gets funneled upwards; instead of breaking the site,
 * we will catch the error and display it
 */
export default class AppCrashBoundary extends Component<any, State> {
  constructor(props) {
      super(props);      
      this.state = { hasError: true, error: props.error.toString(), trace: props.error.stack };
  }

  render() {
      if (this.state.hasError) {
          return (
            <div className="container mt-3 rounded border border-danger">
              <h1>The site had a fatal crash. Contact developer.</h1>
              <p className="btn btn-primary">
                <Button                  
                  onClick={() => document.location.reload()}
                  buttonText="Click Here to Reload Page"
                  className='btn btn-primary'
                />
              </p>
              <p>
                <div><strong>Error:</strong></div>
                <div>{this.state.error}</div>
              </p>
              <p className="text-xs mt-3">
                <div><strong>Trace:</strong></div>
                <table>
                  <tbody>
                    {this.state.trace && this.state.trace.split('\n').map((v, idx) => (
                      <tr key={idx}>
                        {v.split('@').map(v2 => v2.replace(window.location.origin, '')).map((v2, idx2) => (
                          <td key={idx2} className='error-boundary-text'>
                            {v2}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </p>
            </div>
          );
      }
      return this.props.children;
    }
}