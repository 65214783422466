import ReactGA from "react-ga";

export const InitGA = (trackingID: string) => {
    ReactGA.initialize(trackingID);
};

export const TrackPageView = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
};

export const TrackGAEvent = (category: string, action: string, label: string) => {
  ReactGA.event({
      category: category,
      action: action,
      label: label
  })
};