import React, { Component } from "react";
import { About, TaxReport } from "components";
import "./LandingBody.css";
import MESSAGE from "helpers/Message";

class LandingBody extends Component {
  render() {
    return (
      <div className="pre-bds container-fluid landing-page-body row">
        <div className="landing-page-body row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <About
              about_title={MESSAGE.about_title}
              about_info={MESSAGE.about_info}
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <TaxReport
              report_title={MESSAGE.report_title}
              report_heading_one={MESSAGE.report_heading_one}
              report_price_one={MESSAGE.report_price_one}
              view_sample={MESSAGE.view_sample}
              report_heading_two={MESSAGE.report_heading_two}
              report_price_two={MESSAGE.report_price_two}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default LandingBody;
