import React from "react";

interface LabelFieldProps {
  labelText?: string;
  className: string;
  htmlFor?: string;
  prefixText?: string;
}

const LabelField: React.FC<LabelFieldProps> = ({
  labelText,
  className,
  htmlFor,
  prefixText
}) => {
  return (
    <label htmlFor={htmlFor} className={className}>
      <b>{prefixText}</b>{labelText}
    </label>
  );
};

export default LabelField;
