import { addMonths, lastDayOfMonth, startOfMonth, subMonths } from 'date-fns';
import { RadioGroupItem } from '@ftdr/blueprint-components-react';

const previousFilterDatePresets = [
  {
    label: 'Last 12 months',
    start: subMonths(new Date(), 12),
    end: new Date(),
    id: 'last_12_months'
  },
  {
    label: 'Last 6 months',
    start: subMonths(new Date(), 6),
    end: new Date(),
    id: 'last_6_months'
  },
  {
    label: 'Last 3 months',
    start: subMonths(new Date(), 3),
    end: new Date(),
    id: 'last_3_months'
  }
];

const todayFilterDatePresets = [
  {
    label: 'Today',
    start: new Date(),
    end: new Date(),
    id: 'today'
  }
];

const currentMonthFilterDatePreset = [
  {
    label: 'This month',
    start: startOfMonth(new Date()),
    end: lastDayOfMonth(new Date()),
    id: 'this_month'
  }
];

const customFilterDatePreset = [
  {
    label: 'Custom',
    start: null,
    end: null,
    id:'custom'
  }
];

export const defaultFilterDatePresets = [
  ...previousFilterDatePresets,
  ...todayFilterDatePresets,
  ...currentMonthFilterDatePreset,
  ...customFilterDatePreset
];

export enum dateTypes {
  ENTRYDATE = 'ENTRYDATE',
}

