import Option from "./Option";


class County implements Option{
    text : string;
    countyNum : number;
    constructor(text : string, countyNum : number) {
        this.text = text;
        this.countyNum = countyNum;
    }

    getText(): string {
        return this.text
    }
    getValue(): number {
        return this.countyNum
    }

}

 //TODO Use javascript sort rather than relying on the order of the counties in this list being sorted
export let counties = [
    new County("Alameda", 32),
    new County("Alpine",31),
    new County("Amador", 26),
    new County("Butte", 13),
    new County("Calaveras",30),
    new County("Colusa", 16),
    new County("Contra Costa", 28),
    new County("Del Norte", 1),
    new County("El Dorado", 25),
    new County("Fresno", 43),
    new County("Glenn", 12),
    new County("Humboldt", 4),
    new County("Imperial",57),
    new County("Inyo", 45),
    new County("Kern", 49),
    new County("Kings", 46),
    new County("Lake", 11),
    new County("Lassen", 7),
    new County("Los Angeles", 53),
    new County("Madera", 44),
    new County("Marin", 27),
    new County("Mariposa", 36),
    new County("Mendocino", 8),
    new County("Merced", 39),
    new County("Modoc", 3),
    new County("Mono", 35),
    new County("Monterey", 41),
    new County("Napa", 21),
    new County("Nevada", 19),
    new County("Orange", 54),
    new County("Placer", 20),
    new County("Plumas", 10),
    new County("Riverside", 55),
    new County("Sacramento", 24),
    new County("San Benito", 42),
    new County("San Bernardino", 50),
    new County("San Diego", 56),
    new County("San Francisco",58),
    new County("San Joaquin", 29),
    new County("San Luis Obispo", 48),
    new County("San Mateo", 37),
    new County("Santa Barbara", 51),
    new County("Santa Clara", 38),
    new County("Santa Cruz", 40),
    new County("Shasta", 6),
    new County("Sierra", 14),
    new County("Siskiyou", 2),
    new County("Solano", 23),
    new County("Sonoma", 15),
    new County("Stanislaus", 33),
    new County("Sutter", 17),
    new County("Tehama", 9),
    new County("Trinity", 5),
    new County("Tulare", 47),
    new County("Tuolumne", 34),
    new County("Ventura", 52),
    new County("Yolo", 22),
    new County("Yuba", 18)
    ];

export default counties;
