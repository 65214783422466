import React from "react";
import {
  LabelField,
  InputField,
  RadioField,
  Dropdown,
  CalenderPicker
} from "components";
import { EscrowInfoModel, FormFieldModel } from "components/Models";

interface EscrowInfoProps {
  defaultFields: EscrowInfoModel;
  fieldValues: FormFieldModel;
  isSubmitted: boolean;
  alwaysRequireClosingDate?: boolean;
  onChangeHandler: (
    event: React.ChangeEvent<HTMLInputElement>,
    datePickerObject?: any
  ) => void;
}

const EscrowInfo: React.FC<EscrowInfoProps> = ({
  defaultFields,
  fieldValues,
  isSubmitted,
  alwaysRequireClosingDate,
  onChangeHandler
}) => {
  return (
    <>
      <fieldset>
        <legend className="col-lg-9 col-md-9 col-sm-12 col-xs-12 p-0 m-0">
          <LabelField
            className="form-title"
            labelText={defaultFields.escrow_information}
          />
        </legend>
        <div>
          <label className="font-weight-bold f-13 req">
            {defaultFields.do_you_have_escrow_information}
          </label>
        </div>
        <div className="row col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="custom-control d-inline custom-radio mr-4">
            <RadioField
              value="Yes"
              name={fieldValues.escrowYesNo.name}
              label="Yes"
              id="escrowYesNo-1"
              className="custom-control-input"
              isSubmitted={isSubmitted}
              handleClick={onChangeHandler}
            />
          </div>
          <div className="custom-control d-inline custom-radio ml-3">
            <RadioField
              value="No"
              name={fieldValues.escrowYesNo.name}
              label="No"
              id="escrowYesNo-2"
              className="custom-control-input"
              defaultChecked={true}
              handleClick={onChangeHandler}
              isSubmitted={isSubmitted}
            />
          </div>
        </div>
        {fieldValues.escrowYesNo.value === "Yes" && (
          <>
            <div className="row m-0 pt-30">
              <legend className="col-lg-9 col-md-9 col-sm-12 col-xs-12 p-0 m-0">
                <LabelField
                  className="form-title"
                  labelText={defaultFields.escrow_details}
                />
              </legend>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <InputField
                  {...fieldValues.escrowOfficerFirstName}
                  placeholder={defaultFields.enter_first_name}
                  label={defaultFields.escrow_officer}
                  isSubmitted={isSubmitted}
                  className="form-control form-placeholder"
                  handleChange={onChangeHandler}
                  maxLength={49}
                  refr={fieldValues.escrowOfficerFirstName.refr}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-30">
                <InputField
                  {...fieldValues.escrowOfficerLastName}
                  placeholder={defaultFields.enter_last_name}
                  isSubmitted={isSubmitted}
                  className="form-control form-placeholder"
                  handleChange={onChangeHandler}
                  maxLength={49}
                  refr={fieldValues.escrowOfficerLastName.refr}
                />
              </div>
            </div>
            <InputField
              {...fieldValues.email3}
              placeholder={defaultFields.enter_email}
              label={defaultFields.escrow_officer_email}
              required={true}
              isSubmitted={isSubmitted}
              className="form-control form-placeholder"
              handleChange={onChangeHandler}
              maxLength={100}
            />
            <InputField
              {...fieldValues.escrowOfficerNumber}
              placeholder={defaultFields.enter_phone_number}
              label={defaultFields.escrow_officer_number}
              isSubmitted={isSubmitted}
              className="form-control form-placeholder"
              handleChange={onChangeHandler}
              maxLength={50}
            />
            <InputField
              {...fieldValues.escrowCompanyName}
              placeholder={defaultFields.enter_company_name}
              label={defaultFields.escrow_company_name}
              isSubmitted={isSubmitted}
              className="form-control form-placeholder"
              handleChange={onChangeHandler}
              maxLength={100}
            />

            <InputField
              {...fieldValues.escrowCompanyAddress}
              placeholder={defaultFields.enter_street_address}
              label={defaultFields.escrow_company_address}
              isSubmitted={isSubmitted}
              className="form-control form-placeholder"
              handleChange={onChangeHandler}
              maxLength={200}
            />
            <div className="row">
              <div className="col-5">
                <InputField
                  {...fieldValues.city}
                  placeholder={defaultFields.enter_city}
                  label={defaultFields.city}
                  isSubmitted={isSubmitted}
                  className="form-control form-placeholder"
                  handleChange={onChangeHandler}
                  maxLength={50}
                />
              </div>
              <div className="col-3">
                <Dropdown
                  {...fieldValues.state}
                  className="form-control"
                  label={defaultFields.state}
                  options={defaultFields.state_name}
                  handleChange={onChangeHandler}
                  isSubmitted={isSubmitted}
                />
              </div>
              <div className="col-4">
                <InputField
                  {...fieldValues.zipCode}
                  placeholder={defaultFields.enter_zip}
                  label={defaultFields.zip_code}
                  isSubmitted={isSubmitted}
                  className="form-control form-placeholder"
                  handleChange={onChangeHandler}
                  validateZip={true}
                  maxLength={10}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <InputField
                  {...fieldValues.escrowNumber}
                  placeholder={defaultFields.enter_escrow_number}
                  label={defaultFields.escrow_number}
                  isSubmitted={isSubmitted}
                  className="form-control form-placeholder"
                  handleChange={onChangeHandler}
                  maxLength={30}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <CalenderPicker
                  {...fieldValues.escrowClosingDate}
                  label={defaultFields.estimated_closing_date}
                  isSubmitted={isSubmitted}
                  className="form-control form-placeholder"
                  handleChange={onChangeHandler}
                  showOptionalKey={!alwaysRequireClosingDate}
                  showLeadingZeros={true}
                  calendarType="US"
                  clearIcon={null}
                  required={alwaysRequireClosingDate}
                />
              </div>
            </div>
          </>
        )}
        {fieldValues.escrowYesNo.value === "No" && alwaysRequireClosingDate && (
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pt-30">
              <CalenderPicker
                  {...fieldValues.escrowClosingDate}
                  label={defaultFields.estimated_closing_date}
                  isSubmitted={isSubmitted}
                  className="form-control form-placeholder"
                  handleChange={onChangeHandler}
                  showOptionalKey={!alwaysRequireClosingDate}
                  showLeadingZeros={true}
                  calendarType="US"
                  clearIcon={null}
                  required={alwaysRequireClosingDate}
              />
            </div>
          </div>
        )}
      </fieldset>
    </>
  );
};

export default EscrowInfo;
