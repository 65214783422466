import React from "react";
import Address from "./Models/address";
import { Button } from "components";
import "./confirmAddress.css";

export interface UnknownAddressModalContentProps {
    addressFromBackend: Address | undefined,
    addressFromInput: Address | undefined;
    onEditAddress: any;
    onUseAddress: any;
}

const ConfirmAddress: React.FC<UnknownAddressModalContentProps> = ({
    addressFromBackend,
    addressFromInput,
    onEditAddress,
    onUseAddress
}) => {
    const backEndString = addressFromBackend?.verified ?
        addressFromBackend.city + ", " + addressFromBackend.state + " " + addressFromBackend.zipCode
        : "Address Not Found";

    const inputUnitString = addressFromInput?.unitNumber === "" ?
        "" :
        " " + addressFromInput?.unitNumber;

    const foundUnitString = addressFromBackend?.unitNumber === "" ?
        "" :
        " " + addressFromBackend?.unitNumber;

    return (
      <div className="typo-address-confirmation-modal-content">
          <div className="styled-text-root type-headline-4 align-center color-black">
              Please Confirm Your Address</div>
          <p className="styled-text-root type-paragraph align-left color-black bold-true address-headline">
              Did You Mean:</p>
          <hr className="horizontal-divider" />
          <div className="address-candidate">
              <div className="address-text">
                  <div>
                      {addressFromBackend?.streetAddress + foundUnitString}
                  </div>
                  <div >
                      {backEndString}
                  </div>
              </div>
              <Button disabled={!addressFromBackend?.verified}
                      buttonText={"Use"}
                      type="primary"
                      onClick={() => onUseAddress(addressFromBackend)}
                      className="primary-action-button"/>
          </div>
          <hr className="horizontal-divider" />
              <p className="styled-text-root type-paragraph align-left color-black bold-true address-headline">
                  The Address You Entered:</p>
          <hr className="horizontal-divider" />
          <div className="address-candidate">
              <div className="address-text">
                  <div >
                      {addressFromInput?.streetAddress + inputUnitString}
                  </div>
                  <div color="black">
                      {addressFromInput?.state + " " + addressFromInput?.zipCode}
                  </div>
                  <div
                      onClick={onEditAddress}
                        className="type-link">
                      edit address
                  </div>
              </div>
              <Button
                  buttonText={"Use"}
                  type="secondary"
                  onClick={() => onUseAddress(addressFromInput)}
                  className="btnConfirm secondary color-undefined medium action-button"
              />
          </div>
          <hr className="horizontal-divider" />
      </div>
  );
};

export default ConfirmAddress;
