import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Header, Footer, OrderConfirmation } from "components";
import LandingPage from "pages/LandingPage";
import SignUp from "pages/SignUp";
import OrderForm from "pages/OrderForm";
import TermsOfUse from "pages/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";
import MESSAGE from "helpers/Message";
import "./pages/css/pre-bds.css";
import "@ftdr/blueprint-core/themes/ahs-web-app.min.css";
import { isLoggedIn } from "api/services/authService";
import { Redirect } from "react-router-dom";
import { AuthService } from "api";
import ConfigHelper from "helpers/ConfigHelper";
import LocalStorageHelper from "helpers/LocalStorageHelper";
import { UserDataService } from "api";
import { InitGA } from "./components/gaTracking/GATracking";
import Resources from "./pages/Resources/Resources";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Dedupe as DedupeIntegration, CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import AppCrashBoundry from './helpers/AppCrashBoundry';
import Layout from './Layout';
import { PATHS } from './helpers/Constants';
import OrderHistory from 'pages/OrderHistory';
import Dashboard from "pages/Dashboard";
import { Features, IsFeatureEnabled } from "core/featureToggle";



declare global {
  interface Window {
    gaData: any;
  }
}

const ROUTES = [
  {
    path: PATHS.BaseRoute,
    component: LandingPage,
    exact: true
  },
  {
    path: PATHS.SignUp,
    component: SignUp
  },
  {
    path: PATHS.OrderForm,
    component: OrderForm,
    isAuthed: true
  },
  {
    path: PATHS.OrderConfirmation,
    component: OrderConfirmation,
    isAuthed: true,
    props: { orderNo: localStorage.getItem(LocalStorageHelper.NHD_ORDER_ID) }
  },
  {
    path: PATHS.Terms,
    component: TermsOfUse
  },
  {
    path: PATHS.PrivacyPolicy,
    component: PrivacyPolicy
  },
  {
    path: PATHS.Resources,
    component: Resources
  }  
];

interface appProps { }
interface appState {
  isConfigLoaded: boolean;
  isUserDetailsLoaded: boolean;
}

class App extends Component<appProps, appState> {
  constructor(props: appProps) {
    super(props);
    this.state = {
      isConfigLoaded: false,
      isUserDetailsLoaded: false
    };

    this.addBaseFolderPrefixToRoutes();
    this.loadSentryIO();
    this.loadConfig();
    this.loadUserDetails();
    this.addFeatureToggleRoutes();
  }

  private addFeatureToggleRoutes = () => {
    if(IsFeatureEnabled(Features.OrderHistory)){
      ROUTES.push({
        path: ConfigHelper.GetBasePath() + PATHS.OrderHistory,
        component: OrderHistory
      })
    }
    if(IsFeatureEnabled(Features.Dashboard)){
      ROUTES.push({
        path: ConfigHelper.GetBasePath() + PATHS.Dashboard,
        component: Dashboard
      })
    }
  }

  private addBaseFolderPrefixToRoutes = () => {
    ROUTES.forEach((item) => {
      item.path = ConfigHelper.GetBasePath() + item.path;
    });    
  }

  componentDidMount() {
    //Wait for Google Analytics to load

    setTimeout(function () {
      if (window.gaData)
        InitGA(Object.keys(window.gaData)[0]);
    }, 1000);
  }

  errorFallback = ({ error }) => {
    return <AppCrashBoundry error={error} />;
  };


  loadSentryIO = () => {
    try {

      Sentry.init({
        dsn: ConfigHelper.GetConfig("SENTRY_IO_DSN"),
        integrations: [
          new Integrations.BrowserTracing(), // performance monitoring
          new DedupeIntegration(), // dedupes events based on stack traces and fingerprints
        ],
        tracesSampleRate: ConfigHelper.GetConfig("SENTRY_IO_TRACE_SAMPLE_RATE"),
        environment: ConfigHelper.GetConfig("env"),
        maxBreadcrumbs: ConfigHelper.GetConfig("SENTRY_MAX_BREADCRUMBS"),
        beforeBreadcrumb(breadcrumb) {
          if (breadcrumb.category === "console" && !ConfigHelper.GetConfig("SENTRY_BREADCRUMB_LEVEL_WHITELIST").includes(breadcrumb.level)) return null;

          return breadcrumb;
        },
      });

    } catch (e) {
      console.error('failed to initialize sentry.io', e);
    }
  };

  loadConfig() {
    ConfigHelper.LoadConfig().then(response => {
      if (response) {
        this.setState({ isConfigLoaded: true });
      } else {
        console.log("Error loading config.");
      }
    });
  }

  loadUserDetails() {
    UserDataService.GetUserDetails().then(response => {
      if (response) {
        localStorage.setItem(
          LocalStorageHelper.NHD_FIRSTNAME,
          response.firstName
        );
        localStorage.setItem(LocalStorageHelper.NHD_LASTNAME, response.name);
        localStorage.setItem(
          LocalStorageHelper.NHD_USERDETAILS,
          JSON.stringify(response)
        );

      } else {
        LocalStorageHelper.ClearUserData();
      }
      this.setState({ isUserDetailsLoaded: true });
    });
  }

  GetBase

  RouteWithSubRoutes = (route: any) => {
    
    if (route.isAuthed && !AuthService.isLoggedIn()) {
      let basePath = ConfigHelper.GetBasePath();
      return <Redirect to={basePath}/>;
    }

    return (
      <Route
        exact={route.exact}
        path={route.path}
        render={(props) => 
          <Layout>
              <route.component {...props} {...route.props} routes={route.routes} />
          </Layout> 
        }
      />
    );
  };

  render() {
    let basePath = ConfigHelper.GetBasePath();
    return (
      this.state.isConfigLoaded &&
      this.state.isUserDetailsLoaded && (

        <div className="App">
          <Sentry.ErrorBoundary fallback={this.errorFallback}>
            <Router>
              <Header
                nhd_logo={MESSAGE.nhd_logo}
                loggedInUser={
                  isLoggedIn()
                    ? (localStorage.getItem(LocalStorageHelper.NHD_FIRSTNAME) ||
                      "") +
                    " " +
                    (localStorage.getItem(LocalStorageHelper.NHD_LASTNAME) ||
                      "")
                    : MESSAGE.loggedin_user
                }
                sign_up={MESSAGE.sign_up}
                login={MESSAGE.login}
                already_have_account={MESSAGE.already_have_account}
                is_logged_in={isLoggedIn()}
              />
              <Switch>
                {ROUTES.map(route => 
                  <this.RouteWithSubRoutes key={route.path} {...route} />
                  )}
               <Redirect to={basePath}/>;
              </Switch>

              <Footer />

            </Router>
          </Sentry.ErrorBoundary>
        </div >
      )
    );
  }
}



export default App;
