import React, {useEffect} from "react";
import { Dialog} from '@ftdr/blueprint-components-react';

// This modal will prevent scrolling on the body element
// Can be updated to use event listeners with unique names so as to handle multiple modals on screen at once
// THis will break if more than one modal is present as closing a single modal will re-enable scrolling
const ModalBugFix = props => {

    useEffect( () => {

        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = null;
        }
    }, []);

    return (
        <Dialog
            open={props.open}
            hideClose={props.hideClose}
            actions={props.actions}
            onClose={props.onClose}
            modal={props.modal}
            closeOnOutsideClick={props.closeOnOutsideClick}
            className="fixPositionModal forceScroll"
        >
                {props.children}
        </Dialog>
    )
}

export default ModalBugFix;