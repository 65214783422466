export const EMAIL_PATTERN = /\S+@\S+\.\S+/;

export const PATHS = {
    BaseRoute: '/',
    SignUp: '/signup',
    OrderForm: '/orderform',
    OrderConfirmation: '/orderconfirmation',
    Terms: '/terms',
    PrivacyPolicy: '/privacy-policy',
    Resources: '/resources',
    Token: "/token",
    Logout: "/logout",
    ClientConfiguration: "/shield-nhd/client-configuration",
    OrderHistory: '/orderhistory',
    Dashboard: '/dashboard'
};
