import React from "react";
import fileIcon from "assets/img/email.png";
import MESSAGE from "helpers/Message";
import "./OrderConfirmation.css";
import ConfigHelper from "helpers/ConfigHelper";
import { PATHS } from 'helpers/Constants';

interface OrderConfirmationProps {
  orderNo: string;
}

const OrderConfirmation: React.FC<OrderConfirmationProps> = ({ orderNo }) => {
  let pathWithBaseFolder = ConfigHelper.GetBasePath() + PATHS.BaseRoute;
  return (
    <div className='pre-bds'>
      <div className="order-confirmation">
        <img className="icon-style" src={fileIcon} alt="Logo" />
        <p className="title">{MESSAGE.order_confirmation_thankyou}</p>
        <p>
          {MESSAGE.your_order_no} <span className="bold-number">{orderNo}</span>
        </p>
        <a href={pathWithBaseFolder} className="link-btn">
          {MESSAGE.another_order}
        </a>
        <p className="footer-text">
          {MESSAGE.need_AHS_warranty}{" "}
          <strong>
            <a href={MESSAGE.AHS_link}>{MESSAGE.click_here}</a>
          </strong>
        </p>
      </div>
    </div>
  );
};
export default OrderConfirmation;
