import React from "react";
import MESSAGE from "../../helpers/Message";
import "./TermsOfUse.css";

const TermsOfUse: React.FC = () => {
  return (
    <div className="container cms-section  pt-5">
      <h4 className="cms-title mt-3">{MESSAGE.terms_header}</h4>
      <p className="cms-info ml-1">
        Last Updated: {MESSAGE.terms_last_updated}
      </p>
      <div className="pt-2"> 
        <div className="pt-1">
          <p className="content-title pl-4">
            1. What are my Requirements as a User of this Website?
          </p>
          <p>
            Your use of this website is governed by the terms and conditions set
            forth below (the <b>“Terms of Use”</b>). Please read all terms and
            conditions carefully. By using this website, you agree to comply
            with and to be bound by these Terms of Use. If you do not agree to
            these Terms of Use, you may not use this website and should exit
            from it now. U.S. Hazard Disclosure, Inc., along with its parents,
            affiliates, and subsidiaries, owns and operates this website (“
            <b>USHD,</b>” the “<b>Company</b>,” “<b>we</b>” or “<b>us</b>”), and
            reserves the right to change this website and these Terms of Use at
            any time without notice to you by posting such changes to this
            website. You can view the most current version of these Terms of Use
            by clicking on the "Terms of Use" hypertext link located at the
            bottom of the home page of this website.
          </p>         
        </div>

        <div className="pt-1">
          <p className="content-title pl-4">
            2. What Happens if I Place an Order for Services or Products on the
            Site?
          </p>
          <p>
            If you place an order for services or products on the Site, you
            agree to pay for the services or products that you order by means of
            credit card or other accepted form of payment as the Site provides.
            You are fully responsible for all such payments based upon the
            services and products you order, and all such services and products
            will be delivered to you as the Site provides.
          </p>

          <p>
            All services and products available on the Site are subject to all
            of the terms and conditions specific to the services and products
            you order, in addition to any supplemental terms and conditions
            contained in these Terms of Use. Such supplemental terms and
            conditions include, but are not limited to, the limited warranty
            provisions found in paragraph 13 of these Terms of Use and the
            limitations of liability and indemnification provisions found in
            paragraph 14 of these Terms of Use. In case of conflict between
            these Terms of Use and any terms and conditions specific to products
            or services that you order, the terms and conditions specific to
            products or services you order shall control.
          </p>
        </div>

        <div className="pt-1">
          <p className="content-title pl-4">
            3. What Usage Restrictions Apply?
          </p>

          {/* write content below */}
          <p>
            You may not copy, distribute, or transmit any of the content of this
            website without our prior written consent. We reserve complete title
            and full intellectual property rights for materials on this website.
            We provide the content of this website for lawful purposes only.
          </p>

          <p>
            You may not use any “deep-link”, “page-scrape”, “robot”, “spider” or
            other automatic device, program, algorithm or methodology, or any
            similar or equivalent manual process, to access, acquire, copy or
            monitor any portion of this website or any of its content, or in any
            way reproduce or circumvent the navigational structure or
            presentation of this website or any of its content, to obtain or
            attempt to obtain any materials, documents or information through
            any means not purposely made available through this website. We
            reserve the right to bar any such activity.
          </p>

          <p>
            You may not attempt to gain unauthorized access to any portion or
            feature of this website, or any other systems or networks connected
            to this website or to any of our servers, or to any of the services
            offered on or through this website, by hacking, password “mining” or
            any other illegitimate means.
          </p>

          <p>
            You may not probe, scan or test the vulnerability of this website or
            any network connected to this website, nor breach the security or
            authentication measures on this website or any network connected to
            this website. You agree not to use any device, software or routine
            to interfere or attempt to interfere with the proper working of this
            website or any transaction being conducted on this website, or with
            any other person’s use of this website.
          </p>

          <p>
            You may not use this website or any of its content for any purpose
            that is unlawful or prohibited by these Terms of Use, or to solicit
            the performance of any illegal activity or other activity which
            infringes our rights or the rights of others.
          </p>

          <p>
            We cannot and do not guarantee or warrant that files made available
            for downloading through this website will be free of infection or
            viruses, worms, Trojan horses or other code that manifests
            contaminating or destructive properties. By accessing this website,
            you acknowledge and agree that it is your responsibility to
            implement sufficient safeguards and procedures to ensure that any
            files obtained through this website are free from such
            contamination.
          </p>
        </div>

        <div className="pt-1">
          <p className="content-title pl-4">
            4. Are There Links to Other Websites?
          </p>

          <p>
            This website contains links to third-party websites. These links are
            provided solely as a convenience to you and not as an endorsement by
            us of the companies or the content of any linked third-party
            websites. We do not control nor are we responsible for the content
            of linked third-party websites, and we make no representations
            regarding the content or accuracy of materials on the linked
            third-party websites. If you access any linked third-party websites,
            then you do so at your own risk.
          </p>
        </div>

        <div className="pt-1">
          <p className="content-title pl-4">
            5. How Do Privacy and Protection of Personal Information Work?
          </p>

          <p>
          We are committed to protecting your privacy and security. For more 
          information, you should review our <a className="custom-link"
              href="https://www.shieldnhd.com/privacy-policy">Privacy Policy</a>, 
          which is incorporated into these Terms of use by reference. Additionally, 
          by using this website, you acknowledge and agree that Internet transmissions 
          are never completely private or secure. You understand that any message or 
          information you send to this website may be read or intercepted by others, 
          even if there is a special notice that a particular transmission (for example, 
          credit card information) is encrypted.
          </p>
        </div>

        <div className="pt-1">
          <p className="content-title pl-4">
            6. Are there Accounts, Profiles, Passwords and Security on this
            Site?
          </p>

          <p>
            Certain features or services offered on or through this website may
            require you to open an account (including setting up a username and
            password) or create a profile for use in applying for something (for
            example, a job). You are entirely responsible for maintaining the
            confidentiality of the information you hold for your account,
            including your password, and for any and all activity that occurs
            under your account as a result of you failing to keep this
            information secure and confidential. You agree to notify us
            immediately of any unauthorized use of your account or password, or
            any other breach of security. You may be held liable for losses
            incurred by us or any other user of or visitor to this website due
            to someone else using your username, password or account as a result
            of your failing to keep your account information secure and
            confidential.
          </p>

          <p>
            You may not use anyone else’s username, password or account at any
            time without the express permission and consent of the holder of
            that username, password or account. We cannot and will not be liable
            for any loss or damage arising from your failure to comply with
            these obligations.
          </p>
        </div>

        <div className="pt-1">
          <p className="content-title pl-4">
            7. What Do I Need to Know about Trademarks?
          </p>

          <p>
            AMERICAN HOME SHIELD® and certain other trademarks used on this
            website are trademarks of Company or its affiliates, registered in
            the United States of America and many other countries. Absent a
            written license agreement, you may not use these trademarks or any
            other trademarks belonging to Company or its affiliates except for
            descriptive purposes. Other trademarks used on this website are the
            property of their respective owners and are used in this website
            solely for descriptive purposes. Mention on this website of
            trademarks held by other parties should not be construed as a
            challenge to such trademarks' status or ownership.
          </p>
        </div>


        <div className="pt-1">
          <p className="content-title pl-4">
            8. What Do I Need to Know about Copyrights?
          </p>

          <p>
            The entire contents of this website, including but not limited to:
            text, graphics, logos, button icons, Flash movies, images, audio
            clips, digital downloads, data compilations and software, are the
            property of Company, its content suppliers, affiliates or its
            clients and are protected by domestic and international trade dress,
            patent, copyright and trademark laws and various other intellectual
            property rights and unfair competition laws. You may not modify,
            copy, reproduce, republish, upload, post, transmit or distribute any
            portion of the website contents without our prior express written
            consent.
          </p>

          <p>
            Your submissions of files, including, but not limited to: resumes,
            email, flash movies, images, logos, audio loops and other software
            hereby constitutes your agreement to grant us a nonexclusive,
            royalty-free, worldwide, sublicensable, perpetual license to
            reproduce, distribute, transmit, modify, adapt, sublicense and
            publicly display any such submissions. You also grant us the right
            to utilize your name in connection with all advertising, marketing
            and promotional materials related thereto.
          </p>
        </div>

        <div className="pt-1">
          <p className="content-title pl-4">
            9. Is International Use an Issue?
          </p>

          <p>
            Given the global nature of the Internet, you agree to comply with
            all local rules, including, without limitation, rules about the
            Internet, data, email, privacy, copyright and trademark
            infringement. Additionally, you agree to comply with all applicable
            laws regarding the transmission of technical data exported from the
            United States or the country in which you reside.
          </p>
        </div>

        <div className="pt-1">
          <p className="content-title pl-4">10. Do You Provide Any Warranty?</p>

          <p>
            WE PROVIDE THIS WEBSITE AND ITS CONTENT AND ALL SERVICES AND
            PRODUCTS AVAILABLE THROUGH THIS WEBSITE "AS IS" AND WITHOUT ANY
            REPRESENTATION OR WARRANTY, EXPRESS, IMPLIED, OR STATUTORY. WE
            SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTIES OF TITLE,
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE DO NOT
            REPRESENT OR WARRANT THAT THIS WEBSITE WILL BE ACCESSIBLE WITHOUT
            INTERRUPTION OR THAT THIS WEBSITE, ANY CONTENT, SERVICES AND
            PRODUCTS FROM THIS WEBSITE, OR THE SERVER THAT MAKES THIS WEBSITE
            AVAILABLE ARE FREE FROM ERRORS, DEFECTS, DESIGN FLAWS, OMISSIONS,
            VIRUSES, OR OTHER HARMFUL COMPONENTS. YOUR USE OF THIS WEBSITE IS AT
            YOUR OWN RISK. SOME STATES DO NOT ALLOW THE DISCLAIMER OF IMPLIED
            WARRANTIES, IN WHICH CASE PORTIONS OF THIS DISCLAIMER MAY NOT APPLY
            TO YOU.
          </p>
        </div>

        <div className="pt-1">
          <p className="content-title pl-4">
            11. What Limitations of Liability and Indemnifications Apply?
          </p>

          <p>
            IN NO EVENT WILL WE BE LIABLE, WHETHER IN CONTRACT, WARRANTY, TORT
            (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE, OR IMPUTED), PRODUCT
            LIABILITY, STRICT LIABILITY, OR OTHER THEORY, TO YOU OR ANY OTHER
            PERSON FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION, ANY DIRECT,
            INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL
            DAMAGES), ARISING OUT OF OR IN CONNECTION WITH ANY USE OF, THE
            INABILITY TO USE, OR THE RESULTS OF USE OF THIS WEBSITE, ITS CONTENT
            OR THE SERVICES AND PRODUCTS AVAILABLE THROUGH THIS WEBSITE, EVEN IF
            WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME
            JURISDICTIONS PROHIBIT THE EXCLUSION OR LIMITATION OF LIABILITY FOR
            CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN WHICH CASE PORTIONS OF THIS
            LIMITATION MAY NOT APPLY TO YOU. IN NO EVENT WILL WE BE LIABLE OR
            RESPONSIBLE FOR ANY ERRORS OR OMISSIONS IN THE CONTENT OF THIS
            WEBSITE, INCLUDING, WITHOUT LIMITATION, ERRORS IN PRICING OR
            AVAILABILITY OF SERVICES AND PRODUCTS, OR DAMAGES THAT MAY RESULT
            FROM MISREPRESENTATION OF AGE BY A USER OF THIS WEBSITE.
          </p>

          <p>
            You agree to indemnify, defend and hold harmless Company, its
            parent, affiliates, shareholders, agents, licensors, suppliers, and
            any third-party information provider to this website, together with
            their officers, directors, owners, partners and employees, from and
            against all losses, expenses, damages and costs, including
            attorney’s fees, resulting from: (a) any violation of these Terms of
            Use (including negligent or wrongful conduct) by you; or (b) your
            use of and access to this website.
          </p>
        </div>

        <div className="pt-1">
          <p className="content-title pl-4">
            12. What if I Violate these Terms of Use?
          </p>

          <p>
            You agree that we, in our sole discretion and without prior notice,
            may terminate your access to this website and/or block your future
            access to this website if we determine that you have violated these
            Terms of Use or other agreements or guidelines which may be
            associated with your use of this website. You also agree that any
            violation by you of these Terms of Use will constitute an unlawful
            and unfair business practice, and will cause irreparable harm to us,
            for which monetary damages would be inadequate; and you consent to
            our obtaining any injunctive or equitable relief that we deem
            necessary or appropriate in such circumstances. These remedies are
            in addition to any other remedies we may have at law or in equity.
          </p>

          <p>
            You agree that we, in our sole discretion and without prior notice,
            may terminate your access to this website for any lawful reason,
            including for cause, which includes: (1) requests by law enforcement
            or other government agencies, (2) a request by you (self-initiated
            account deletions), (3) discontinuance or material modification of
            this website or any service offered on or through this website, or
            (4) unexpected technical issues or problems.
          </p>
        </div>


        <div className="pt-1">
          <p className="content-title pl-4">13. How are Disputes Resolved?</p>

          {/* write content below */}
          <p>
            <b>Governing Law/Disputes.</b> These Terms of Use shall be governed
            by and construed in accordance with the laws of the State of
            Tennessee without application of conflict of laws rules. Venue for
            all Claims (defined below) not subject to arbitration shall be in
            state or federal courts located in Shelby County, Tennessee.
          </p>

          <p>
            <b>Resolution of any Dispute.</b> If you are using this site, and
            there is an issue that needs to be resolved, the following terms
            describe how both of us will proceed:
          </p>

          <p>
            <b>Limitation of Legal Remedies.</b> INSTEAD OF SUING IN COURT, YOU
            AND WE EACH AGREE TO ARBITRATE DISPUTES ON A BILATERAL (INDIVIDUAL)
            BASIS. You and we agree that any dispute, controversy or claim
            arising out of your use of this website or its content ("
            <b>Claim(s)</b>") which cannot be settled by mutual agreement of the
            parties shall be resolved by one arbitrator through binding
            arbitration. This agreement to arbitrate is intended to be broadly
            interpreted. It includes Claims based in contract, tort, statute,
            fraud, misrepresentation or any other legal theory. The arbitrator’s
            decision and award are final and binding, with some exceptions under
            the Federal Arbitration Act, 9 U.S.C. 1, et seq., and judgment on
            the award may be entered in any court with jurisdiction.
          </p>

          <p>
            <b>Class Action Waiver.</b> You and we each agree that each of us is
            waiving the right to a trial by jury and may bring claims against
            the other only in an individual capacity and not in a class action
            or representative proceeding. All arbitrations shall be conducted on
            an individual (and not a class-wide) basis; and an arbitrator shall
            have no authority to award class-wide relief. You acknowledge and
            agree that these terms specifically prohibit you from commencing any
            legal proceedings as a representative of others or joining in any
            arbitration proceedings brought by any other person (“
            <b>Class Action Waiver</b>”).
          </p>

          <p>
            <b>Arbitration Procedures.</b> A party who intends to seek
            arbitration must first send to the other a written Notice of Dispute
            describing the nature and basis of the Claim and setting forth the
            specific relief sought. All notices to us shall be sent to the
            following address: U.S. Hazard Disclosure, Inc., 150 Peabody Pl.
            Suite 300, Memphis, TN, 38103, ATTN: Legal Department –
            Administrator, Alternative Dispute Resolution. All notices to you
            will be sent to the email or physical address you have provided.
            Upon receipt of such notice, the other party shall have a sixty (60)
            day period in which it may satisfy the Claim against it by fully
            curing the Claim and/or providing all the relief requested in the
            notice, and/or entering into a settlement to resolve the Claim to
            the mutual satisfaction of the parties. After the expiration of such
            sixty-day cure period, you or we may commence an arbitration
            proceeding. The arbitration of any claim or dispute under this
            Agreement shall be referred to the American Arbitration
            Association’s (“<b>AAA</b>”) under its rules and procedures,
            including the AAA's Commercial Dispute Resolution Procedures and
            Supplementary Procedures for Consumer-Related Disputes. These rules
            and procedures are available by calling the AAA or by visiting its
            website at{" "}
            <a
              className="custom-link"
              href="https://www.ahs.com/www.adr.org"
            >
              www.adr.org
            </a>
            . The arbitration of any Claim shall be conducted in the State of
            Tennessee, and for any non-frivolous claim that does not exceed
            $10,000, Company will: (1) pay all costs of the arbitration; (2) if
            you prefer will conduct the arbitration by telephone, and (3) will
            not seek attorney’s fees in the event Company prevails. Each party
            shall pay the fees and costs of its own counsel, experts and
            witnesses.
          </p>

          <p>
            <b>Choice of Law.</b> This Agreement concerns a transaction in
            interstate commerce, and therefore shall be governed by the United
            States Federal Arbitration Act (the “<b>FAA</b>”), 9 U.S.C. § 1 et
            seq. The FAA’s provisions, not state law, govern all questions of
            whether a dispute is subject to arbitration.
          </p>

          <p>
            <b>Exception.</b> Any claim or action for indemnification,
            contribution, interpleader or injunctive relief arising out of a
            Claim shall not be subject to arbitration.
          </p>
        </div>


        <div className="pt-1">
          <p className="content-title pl-4">
            14. Are there Any Additional Terms?
          </p>

          <p>
            Our failure to insist upon or enforce strict performance of any
            provision of these Terms of Use shall not be construed as a waiver
            of any provision or right. Neither the course of conduct between the
            parties nor trade practice shall act to modify any provision of
            these Terms of Use. We may assign our rights and duties under these
            Terms of Use to any party at any time without notice to you. If any
            of these terms shall be deemed invalid, void or, for any reason,
            unenforceable, that term shall be deemed severable and shall not
            affect the validity or enforceability of any remaining term. Any
            rights not expressly granted herein are reserved.
          </p>
        </div>

        <div className="pt-1">
          <p className="content-title pl-4">15. Entire Agreement</p>

          <p>
            These Terms of Use constitute the entire agreement between you and
            us regarding this website. These Terms of Use supersede all prior or
            contemporaneous communications and proposals, whether electronic,
            oral, or written, between you and us regarding this website. No
            modification of these Terms of Use will be effective unless we
            authorize it.
          </p>
        </div>

        <div className="pt-1">
          <p className="content-title pl-4">16. Address of Record</p>

          <p>
            U.S. Hazard Disclosure, Inc.
            <br />
            150 Peabody Place Suite 300
            <br />
            Memphis, TN 38103
            <br />
            United States of America
          </p>
        </div>

      </div>
    </div>
  );
};

export default TermsOfUse;
