import { post } from "utils/http-clients/json-http-client";
import { default as queryStringParser } from "query-string";
import ConfigHelper from "helpers/ConfigHelper";
import { TrackGAEvent } from "../../components/gaTracking/GATracking";
import LocalStorageHelper from "helpers/LocalStorageHelper";
import {PATHS} from 'helpers/Constants';

const FORM_URL_ENCODED_CONTENT_TYPE = "application/x-www-form-urlencoded";

const encodeURIKeyValue = (uriKeyValue: [string, string]) => uriKeyValue.map(encodeURIComponent).join("=");

const mapToRequestParams = (paramsMap: { [key: string]: string }) =>
    Object.entries(paramsMap)
        .map(encodeURIKeyValue)
        .join("&");

function getRedirectBase() {
    let base = window.location.origin + ConfigHelper.GetBasePath();
    //let base = ConfigHelper.GetBasePath();
    if (base.substring(-1) === PATHS.BaseRoute) {
        return base.substr(0, base.length - 1)
    }
    return base
}

export interface AuthState {
    userId: string | undefined;
    email: string | undefined;
    accessToken: string | undefined;
    tokenType: string | undefined;
    expiresIn: number | undefined;
    refreshToken: string | undefined;
    authRedirectUri: string | undefined;
    appRedirectUri: string | undefined;
}

export const getTokenExpirationTime = () => tokenExpirationTime;


export const getTokensUsingRefreshToken = async (
    refreshToken: string,
    redirectUri: string
): Promise<Partial<AuthState> | undefined> => {
    const requestParams = {
        client_id: ConfigHelper.IdentityProviderClientID,
        refresh_token: refreshToken,
        grant_type: "refresh_token",
        scope: "offline_access",
        redirect_uri: encodeURI(getRedirectBase())
    };
    return fetchTokens(requestParams, redirectUri, refreshToken);
};

export const getTokensUsingAuthCode = async (
    authCode: string,
    redirectUri: string
): Promise<Partial<AuthState> | undefined> => {
    var cleanUri = redirectUri;

    if (redirectUri.substr(redirectUri.length - 1, redirectUri.length) === PATHS.BaseRoute) {
        cleanUri = redirectUri.substr(0, redirectUri.length - 1);
    }
    const requestParams = {
        client_id: ConfigHelper.IdentityProviderClientID,
        code: authCode,
        grant_type: "authorization_code",
        scope: "offline_access",
        redirect_uri: encodeURI(cleanUri)
    };
    return await fetchTokens(requestParams, cleanUri);
}

const fetchTokens = async (
    requestParams: { [key: string]: string },
    redirectUri: string,
    refreshToken?: string
): Promise<Partial<AuthState> | undefined> => {
    try {
        const response = await post(
            ConfigHelper.IdentityProviderBaseUrl + PATHS.Token,
            mapToRequestParams(requestParams),
            FORM_URL_ENCODED_CONTENT_TYPE,
            true
        );

        const auth = getAuthStateFromResponse(response, redirectUri, refreshToken);
        return Promise.resolve(auth);
    } catch (e) {
        TrackGAEvent("service", "failed", "Fetch token service failed");
        return Promise.resolve(undefined);
    }
};

const getAuthStateFromResponse = (response: any, redirectUri: string, refreshToken?: string): Partial<AuthState> => ({
    refreshToken: refreshToken || response.refresh_token,
    accessToken: response.access_token,
    expiresIn: response.expires_in,
    tokenType: response.token_type,
    userId: response.userId,
    authRedirectUri: redirectUri,
    email: getEmailAddressFromAccessToken(response.access_token)
});

export const getQueryStringParam = (queryString: string, param: string): string => {
    const code = queryStringParser.parse(queryString)[param];
    if (code !== null && code !== undefined) {
        if (Array.isArray(code)) {
            return code.length > 0 ? code[0] : "";
        } else {
            return code;
        }
    }
    return "";
};

const extractAuthCodeFromURL = () => getQueryStringParam(window.location.search, "code");

const getEmailAddressFromAccessToken = (jwt: string) => {
    const jwtParts = jwt.split(".");
    if (jwtParts.length >= 2) {
        const jwtPayload = jwtParts[1];
        if (jwtPayload) {
            const decodedJwtPayload = atob(jwtPayload);
            if (decodedJwtPayload) {
                try {
                    const jwtPayloadObject = JSON.parse(decodedJwtPayload);
                    return jwtPayloadObject["email"] || "";
                } catch (error) {
                    return "";
                }
            }
        }
    }
    return "";
};

let loggedIn: boolean = false;
let auth: Partial<AuthState> = {}
let tokenExpirationTime : number;

export function isLoggedIn(): boolean {
    return loggedIn;
}

export function loggedInEmail(): string {
    return auth.email || "";
}

export function loggedInFusionAuthId() : string {
    return auth.userId || "";
}

export function getLoginURL(): string {

    return ConfigHelper.IdentityProviderBaseUrl + "/authorize?client_id=" + ConfigHelper.IdentityProviderClientID + "&tenant_id=" + ConfigHelper.IdentityProviderTenantID + "&response_type=code&redirect_uri=" + encodeURI(getRedirectBase())
}

export function getRegisterURL(): string {
    return ConfigHelper.IdentityProviderBaseUrl + "/register?client_id=" + ConfigHelper.IdentityProviderClientID + "&tenant_id=" + ConfigHelper.IdentityProviderTenantID + "&response_type=code&redirect_uri=" + encodeURI(getRedirectBase())
}

export const getLogoutURL = () => {
    const paramsMap = {
        client_id: ConfigHelper.IdentityProviderClientID || "",
        tenant_id: ConfigHelper.IdentityProviderTenantID || "",
        post_logout_redirect_uri: encodeURI(getRedirectBase())
    };
    const queryParams = mapToRequestParams(paramsMap);
    const loginUrl = ConfigHelper.IdentityProviderBaseUrl + PATHS.Logout;
    return `${loginUrl}?${queryParams}`;
};

export const logout = () => {
   try {
        clearTokens();
        clearLocalStorage();

        window.location.assign(getLogoutURL());
    } catch (error) {
        console.error("could not log out")
    }
}

const clearLocalStorage = () => {
    LocalStorageHelper.ClearLocalStorage();
    LocalStorageHelper.ClearUserData();
}



const urlHasAuthCode = () => !!extractAuthCodeFromURL();

export const getAppRedirectUri = () => {
    const { origin, href, hash } = window.location;
    return href
        .replace(origin, "")
        .replace(hash, "")
        .replace(/\/$/, "");
};

export async function login() {
    if (urlHasAuthCode()) {
        const authCode = extractAuthCodeFromURL();
        const appRedirectUriWithParams = getAppRedirectUri();
        const splitUri = appRedirectUriWithParams.split("?");
        const appRedirectUri = splitUri[0];
        const authRedirectUri = window.location.origin + appRedirectUri;
        const auth = await getTokensUsingAuthCode(authCode, authRedirectUri);
        if (auth) {
            setTokens(auth.accessToken || "", auth.refreshToken || "");

            window.location.assign(window.location.href.split('?')[0]);
        }
    }
    else if (tokenExpired()) {
        if (hasRefreshToken()) {
            await refreshToken();
        } else {
            // log back in
            window.location.assign(getLoginURL());
        }
    }
    else {
        authenticate();
    }
}

export function hasRefreshToken() : boolean {
    let rwt = getJWT();
    return rwt !== null && rwt !== "";
}

export function tokenExpired() {
    let jwt = getJWT();
    if (jwt) {
        let jwtObj = parseJwt(jwt || "") as JWT;
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(jwtObj.exp);
        let now = new Date();
        if (now >= d) {
            return true;
        }
    }
    return false;
}

export async function refreshToken() {
    let rwt = getRWT();
    if (rwt) {
        const auth = await getTokensUsingRefreshToken(rwt || "", encodeURI(getRedirectBase()))
        if (auth) {
            setTokens(auth.accessToken || "", auth.refreshToken || "");
        }
    } else {
        return Promise.reject();
    }
}

export type JWT = {
    partner: string,
    iss: string,
    exp: number,
    email?: string,
    sub: string
}

function parseJwt(token: string): JWT {
    var base64Url = token.split(".")[1];
    if (base64Url === undefined) return {} as JWT;
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );
    try {
        let parsed = JSON.parse(jsonPayload);
        return parsed;
    } catch (e) {
        return {} as JWT;
    }
}

export function authenticate() {
    loggedIn = false;
    tokenExpirationTime = 0;
    let jwt = getJWT();
    let jwtIss = ConfigHelper.IdentityIssuer;
    if (jwt) {
        let jwtObj = parseJwt(jwt || "") as JWT;
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(jwtObj.exp);
        let now = new Date();
        if (jwtObj && jwtObj.iss === jwtIss && now < d) {
            loggedIn = true;
            auth.email = jwtObj.email;
            tokenExpirationTime = jwtObj.exp;
            auth.userId = jwtObj.sub;
            return true;
        } else {
            clearJWT();
        }
    }
    return false;
}

function setTokens(jwt: string, rwt: string) {
    if (jwt.trim()) { 
        setCookie("jwt", jwt, 60);
    }

    if (rwt.trim()) {
        //RWT Not Yet Implemented
        //sessionStorage.setItem("rwt", rwt);
    }
}

function clearTokens() {
    clearJWT();
    clearRWT();
}

function clearJWT() {
    setCookie("jwt", "", 0);
}

function clearRWT() {
    setCookie("rwt", "", 0);
}

export function getJWT() {
    return getCookie("jwt");
}

function getRWT() {
    return sessionStorage.getItem("rwt");
}

function setCookie(name: string, value: string, expirationMinutes: number) {
    var d = new Date();
    d.setTime(d.getTime() + (expirationMinutes * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    var basePath = ConfigHelper.GetBasePath();
    document.cookie = name + "=" + value + "; " + expires + "; path=" + basePath;
}

function getCookie(name: string) {
    var re = new RegExp(name + "=([^;]+)");
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
}