import MESSAGE from "../../helpers/Message";
import { FormFieldModel } from "components/Models";
import * as React from "react";

 const agentFirstNameRefr = React.createRef();
 const agentLastNameRefr = React.createRef();
 const agentCompanyRefr = React.createRef();
 const agentPhoneNumberRefr = React.createRef();
 const email1Refr = React.createRef();
 const escrowOfficerFirstNameRefr = React.createRef();
 const escrowOfficerLastNameRefr = React.createRef();
 const escrowOfficerPhoneNumberRefr = React.createRef();
 const escrowOfficerEmailRefr = React.createRef();
 const escrowCompanyNameRefr = React.createRef();
 const sellerNameRefr = React.createRef();
 const sellerPhoneRefr = React.createRef();
 const sellerEmailRefr = React.createRef();

const defaultOrderFormData: FormFieldModel = {
    savedAgents: {
        value: "",
        name: "savedAgents",
        options: [1, 2, 3, 4, 5, 6, 7]
      },
      agentFirstName: {
        value: "",
        type: "text",
        name: "agentFirstName",
        required: true,
        refr: agentFirstNameRefr
      },
      agentLastName: {
        value: "",
        type: "text",
        name: "agentLastName",
        required: true,
        refr: agentLastNameRefr
      },
      agentCompany: {
        value: "",
        type: "text",
        name: "agentCompany",
        required: true,
        refr: agentCompanyRefr
      },
      agentCompanyAddress: {
        value: "",
        type: "text",
        name: "agentCompanyAddress",
        required: false
      },
      agentCompanyCity: {
        value: "",
        type: "text",
        name: "agentCompanyCity",
        required: false
      },
      agentCompanyState: {
        value: "",
        type: "text",
        name: "agentCompanyState",
        required: false
      },
      agentCompanyZipCode: {
        value: "",
        type: "text",
        name: "agentCompanyZipCode",
        required: false
      },
      agentPhoneNumber: {
        value: "",
        type: "tel",
        name: "agentPhoneNumber",
        required: true,
        refr: agentPhoneNumberRefr
      },
      email1: {
        value: "",
        type: "email",
        name: "email1",
        required: true,
        refr: email1Refr
      },
    
      sellerName: {
        value: "",
        type: "text",
        name: "sellerName",
        required: false,
        refr: sellerNameRefr
      },
      sellerPhone: {
        value: "",
        type: "tel",
        name: "sellerPhone",
        showOptionalKey: true,
        refr: sellerPhoneRefr
      },
      email2: {
        value: "",
        type: "email",
        name: "email2",
        showOptionalKey: true,
        refr: sellerEmailRefr
      },
    
      escrowYesNo: {
        name: "escrowYesNo",
        value: "No"
      },
      savedOfficers: {
        value: "",
        name: "savedAgents",
        options: [1, 2, 3, 4, 5, 6, 7]
      },
      escrowOfficerFirstName: {
        value: "",
        type: "text",
        name: "escrowOfficerFirstName",
        required: false,
        refr: escrowOfficerFirstNameRefr
      },
      escrowOfficerLastName: {
        value: "",
        type: "text",
        name: "escrowOfficerLastName",
        required: false,
        refr: escrowOfficerLastNameRefr
      },
      email3: {
        value: "",
        type: "email",
        name: "email3",
        required: false,
        refr: escrowOfficerEmailRefr
      },
      escrowOfficerNumber: {
        value: "",
        type: "tel",
        name: "escrowOfficerNumber",
        required: false,
        refr: escrowOfficerPhoneNumberRefr
      },
      escrowCompanyName: {
        value: "",
        type: "text",
        name: "escrowCompanyName",
        required: false,
        refr: escrowCompanyNameRefr
      },
      escrowCompanyAddress: {
        value: "",
        type: "text",
        name: "escrowCompanyAddress",
        required: false
      },
      city: {
        value: "",
        type: "text",
        name: "city",
        required: false
      },
      state: {
        value: "",
        name: "state",
        required: false
      },
      zipCode: {
        value: "",
        type: "text",
        name: "zipCode",
        required: false
      },
      escrowNumber: {
        value: "",
        type: "text",
        name: "escrowNumber",
        required: false
      },
      escrowClosingDate: {
        value: "",
        type: "date",
        name: "escrowClosingDate",
      },

      taxReportRadio: {
        name: "taxReportRadio",
        value: MESSAGE.report_heading_one
      },
      DeliveryComment: {
        defaultValue: "",
        name: "DeliveryComment"
      },
      "recipientEmail-0": {
        name: "recipientEmail-0",
        value: ''
      },
      "recipientOrderConfirmationCheckbox-0": {
        name: "recipientOrderConfirmationCheckbox-0",
        value: true
      },
   manualApnChange : {
      name: "manualApnChange",
      value: false
   },
   promoCode: {
    name: "promoCode",
    value: ""
  },
  isHspSelected: {
   name: "isHspSelected",
   value: false
 }
}

export default defaultOrderFormData;

