import ConfigHelper from "helpers/ConfigHelper";

export function initFeatureToggle() {
    return ConfigHelper.GetToggleFeatures();
}

export enum Features {
    OrderHistory = 'OrderHistory',
    Dashboard = 'Dashboard'
}

export function IsFeatureEnabled(featureName: Features): boolean {
    let arr = ConfigHelper.GetToggleFeatures();// as [];
    return arr.indexOf(featureName) > -1;
}