import React, { Component } from 'react';
import { FilterOperation, FilterType} from '../../constants/dashboardFilters';
import { orderStatusDictionary, OrderStatus } from '../../enums/OrderStatusType';
import { Checkbox } from '@ftdr/blueprint-components-react';
import { FilterTagItem } from '../Models/filterModel';

interface state {
}

interface props {
  addFilter: any;
  activeFilters: FilterTagItem[];
  filterAlerts?: any;
  roleIDType: any;
  statusMenuType?: StatusMenuType;
} 

export enum StatusMenuType {
  Orders = 'orders'
}

class OrderStatusFilter extends Component<props, state> {

  static defaultProps = {
    statusMenuType: StatusMenuType.Orders
  }

  statusMenu = {
    orders: Object.values(OrderStatus).filter(status => status) as []
  }

  onChange = (status, event) => {
    this.props.addFilter(
      undefined,
      {
        operation: event.target.checked ? FilterOperation.ADD : FilterOperation.REMOVE,
        type: FilterType.STATUS,
        payload: { status },
        label: `Status: ${orderStatusDictionary[status] || status}`,
      });
  }

  render() {
    return (
      <div className="mb-6">
        {this.statusMenu.orders.map(status => {
         
          return (
            <div className="w-full h-6">
              <Checkbox
                checked={this.props.activeFilters.some(filter => filter.payload.status === status)}
                label={orderStatusDictionary[status]}
                onChange={(event) => this.onChange(status, event)}
              />              
            </div>
          );
        })}
      </div>
    );
  }
}

export default OrderStatusFilter;
