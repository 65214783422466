import React from "react";
import {Button,} from "components";
import FileIcon from "assets/img/file.png";
import {OrderFormNhdReportModel} from "components/Models";
import CountyType from "./Models/CountyType";
import MESSAGE from "helpers/Message";
import "./OrderFormNhdReport.css";
import {counties as Counties} from "helpers/Counties";
import Address from "../confirmAddress/Models/address";
import Apn from "../confirmAddress/Models/apn";
import {ReactComponent as ErrorIcon} from "../../assets/icons/form/warning.svg";
import CollectAddressDetails from "../collectAddressDetails/collectAddressDetails";
import CollectApnDetails from "../collectApnDetails/collectApnDetails";
import ConfirmApn from "../confirmApn/confirmApn";
import {TrackGAEvent} from "../gaTracking/GATracking";
import LocalStorageHelper from "helpers/LocalStorageHelper";
import {ApnDataService} from "api";
import ModalChildren from "../modalChildren/ModalChildren";

interface OrderFormNhdReportProps {
    defaultFields: OrderFormNhdReportModel;
    onChange: Function;
    isError: boolean;
    handleApnChangeWithUnit: Function;
}

interface OrderFormNhdReportState {
    location: {
        address?: Address;
        county?: CountyType;
        apn?: string;
    };

    showAddressModal: boolean;
    showApnModal: boolean;
    showApnConfirmation: boolean;
    apnFromBackend: string;
    isCompleteAddress: boolean;
    isAddressCollected: boolean;
    selectedAddress: Address;
    isValidAddress: boolean;
}

class OrderFormNhdReport extends React.Component<OrderFormNhdReportProps,
    OrderFormNhdReportState> {

    constructor(props: OrderFormNhdReportProps) {
        super(props);

        this.state = {
            location: {
                address: props.defaultFields.nhd_report_address,
                county: props.defaultFields.report_county,
                apn: props.defaultFields.property_apn_value
            },

            showAddressModal: false,
            showApnModal: false,
            showApnConfirmation: false,
            apnFromBackend: "",
            isCompleteAddress: true,
            isAddressCollected: false,
            isValidAddress: true,
            selectedAddress: new Address("", "", "CA"),
        };

        this.handleAddressToggleClick = this.handleAddressToggleClick.bind(this);
        this.handleApnToggleClick = this.handleApnToggleClick.bind(this);
    }

    handleAddressToggleClick() {
        this.setState(prevState => ({
            showAddressModal: !prevState.showAddressModal
        }));
    }

    handleApnToggleClick() {
        this.setState(prevState => ({
            showApnModal: !prevState.showApnModal,
        }));
    }

    updateOrderFormHandler = (orderDetails: any) => {
        const property_apn_value = orderDetails.selectedAPN || "";
        const lsCounty = orderDetails.selectedCounty || "";
        const nhd_report_address = JSON.parse(orderDetails.selectedAddress) as Address || undefined;
        const selectedCountyNum = parseInt(lsCounty);

        const report_county =
            (Counties.find(
                county => county.getValue() === selectedCountyNum
            ) as CountyType) || MESSAGE.report_county_default;

        this.props.onChange(nhd_report_address, selectedCountyNum, property_apn_value);

        this.setState({
            location: {
                address: nhd_report_address,
                county: report_county,
                apn: property_apn_value
            },
        });
    };


    logSearchByStreet = () => {
        TrackGAEvent("text field", "type", "use street address");
    };

    handleConfirmApn = (apn: string) => {
        localStorage.setItem(LocalStorageHelper.NHD_APN, apn);
        this.setState({
            location: {
                ...this.state.location,
                apn: apn
            },
            showApnConfirmation: !this.state.showApnConfirmation
        });
        localStorage.setItem(LocalStorageHelper.NHD_APN, apn);

        let orderDetails = {
            selectedAPN: localStorage.getItem(LocalStorageHelper.NHD_APN),
            selectedCounty: localStorage.getItem(LocalStorageHelper.NHD_COUNTY),
            selectedAddress: localStorage.getItem(LocalStorageHelper.NHD_ADDRESS)
        };
        this.updateOrderFormHandler(orderDetails);
    }

    handleUseApn = (apnInfo: Apn, county: string) => {
        console.log(county);
        localStorage.setItem(LocalStorageHelper.NHD_APN, apnInfo.apn);
        localStorage.setItem(LocalStorageHelper.NHD_COUNTY, county);

        this.setState({
            location: {
                ...this.state.location,
                apn: apnInfo.apn
            },
            showApnModal: !this.state.showApnModal
        }, () => {
            //update order form with updated APN info
            let orderDetails = {
                selectedAPN: localStorage.getItem(LocalStorageHelper.NHD_APN),
                selectedCounty: localStorage.getItem(LocalStorageHelper.NHD_COUNTY),
                selectedAddress: localStorage.getItem(LocalStorageHelper.NHD_ADDRESS)
            };
            console.log(localStorage.getItem(LocalStorageHelper.NHD_COUNTY));
            this.updateOrderFormHandler(orderDetails);
        });

    }

    handleUseAddress = (address: Address) => {

        this.setState({
            location: {
                address: address
            },
            showAddressModal: !this.state.showAddressModal
        });

        let lastApn = localStorage.getItem(LocalStorageHelper.NHD_APN);
        this.logSearchByStreet();
        LocalStorageHelper.ClearLocalStorage();

        if (
            !address.streetAddress || !address.city || address.state.trim().toLowerCase() !== "ca" || !address.zipCode
        ) {
            this.setState({isCompleteAddress: false, isAddressCollected: true});
        } else if (this.state.isAddressCollected) {
            this.setState({
                isCompleteAddress: true,
                selectedAddress: address,
                isValidAddress: false,
                isAddressCollected: false
            });
        } else {
            ApnDataService.addressToAPN(address).then(response => {
                var findCounty = Counties.find(
                    county => county.getText() === response.county
                );
                var countyNum = findCounty ? findCounty.getValue() : 0;
                var addressModified = {...address};
                addressModified.city = addressModified.city.trim()
                    ? addressModified.city
                    : response.city;

                // Ask if they want to use this APN or a previously entered one
                let apn = lastApn ? lastApn : "";
                if (response.apn) {
                    if (lastApn !== "" && lastApn !== response.apn) {
                        this.setState({
                            showApnConfirmation: true,
                            apnFromBackend: response.apn
                        });
                    } else {
                        apn = response.apn;
                    }
                }

                const county: CountyType = {
                    countyNum: countyNum,
                    text: response.county
                };

                localStorage.setItem(LocalStorageHelper.NHD_ADDRESS, JSON.stringify(addressModified));
                localStorage.setItem(LocalStorageHelper.NHD_APN, apn);
                localStorage.setItem(LocalStorageHelper.NHD_COUNTY, countyNum.toString());

                this.setState({
                        location: {address, county, apn}
                    },
                    () => {
                        //update order form with updated address info
                        let orderDetails = {
                            selectedAPN: localStorage.getItem(LocalStorageHelper.NHD_APN),
                            selectedCounty: localStorage.getItem(LocalStorageHelper.NHD_COUNTY),
                            selectedAddress: localStorage.getItem(LocalStorageHelper.NHD_ADDRESS)

                        };
                        this.updateOrderFormHandler(orderDetails);
                    });
            });
        }
    };

    onApnPopUpClose = () => {
        this.setState(prev => ({
            showApnModal: !prev.showApnModal
        }))
    }

    onApnConfirmationPopUpClose = () => {
        this.setState(prev => ({
            showApnConfirmation: false
        }))
    }

    onAddressPopUpClose = () => {
        this.setState(prev => ({
            showAddressModal: !prev.showAddressModal
        }))
    }

    render() {

        const {defaultFields} = this.props;

        let fullAddress = "", streetAddress = "", unitNumber = "", city = "", state = "", zipCode = "", apn = "",
            apnFromBackend = "";
        if (this.state.location.address) {
            fullAddress = this.state.location.address.streetAddress + (this.state.location.address.unitNumber ? " " + this.state.location.address.unitNumber : "") + ", " + this.state.location.address.city + ", " + this.state.location.address.state + ", " + this.state.location.address.zipCode;
            streetAddress = this.state.location.address.streetAddress;
            unitNumber = this.state.location.address.unitNumber;
            city = this.state.location.address.city;
            zipCode = this.state.location.address.zipCode;
            state = this.state.location.address.state || "CA";
            apn = this.state.location.apn ? this.state.location.apn : "";
            apnFromBackend = this.state.apnFromBackend ? this.state.apnFromBackend : "";
        }


        return (
            <div className="order-form-nhd-report">
                <div className="text-center mb-4">
                    <img src={FileIcon} alt="File icon" className="mb-2 fileIcon"/>
                    <h6 className="text-center form-title m-0 p-0">
                        {defaultFields.nhd_report_for}
                    </h6>
                </div>
                <div className="form-group">
                    <label
                        htmlFor="NHDReportAddress"
                        className="capitalize gray-secondary m-0 form-label col-9 p-0"
                    >
                        {defaultFields.address}
                    </label>
                    <label
                        className="text-blue m-0 col-3 text-right p-0 cursor-pointer"
                        onClick={this.handleAddressToggleClick}
                    >
                        {this.props.defaultFields.changeAddress}
                    </label>
                    {this.state.showAddressModal && (
                        <CollectAddressDetails
                            defaultStreetAddress={streetAddress}
                            defaultUnitNumber={unitNumber}
                            defaultCity={city}
                            defaultState={state}
                            defaultZip={zipCode}
                            onUseAddress={this.handleUseAddress}
                            onClose={this.onAddressPopUpClose}
                        />
                    )}
                    <p
                        className="form-autosize"
                    >
                        {fullAddress}
                    </p>
                </div>

                <div className="form-group mb-4">
                    <label
                        htmlFor={'propertyApn'}
                        className={'capitalize gray-secondary m-0 form-label col-9 p-0'}
                    >
                        {this.props.defaultFields.property_apn}
                    </label>

                    <label
                        className="text-blue m-0 col-3 text-right p-0 cursor-pointer"
                        onClick={this.handleApnToggleClick}
                    >
                        {this.props.defaultFields.changeApn}
                    </label>
                    {this.state.showApnModal && (
                        <CollectApnDetails
                            defaultApn={apn}
                            defaultState={state}
                            defaultZip={zipCode}
                            defaultCounty={this.state.location.county ? this.state.location.county : null}
                            onUseApn={this.handleUseApn}
                            onClose={this.onApnPopUpClose}
                            apnChangeHandler={this.props.handleApnChangeWithUnit}
                        />
                    )}
                    {this.state.showApnConfirmation && (
                        <ModalChildren>
                            <ConfirmApn
                                apnFromBackend={apnFromBackend}
                                apnFromInput={apn}
                                onUseApn={this.handleConfirmApn}
                                onEditApn={this.onApnConfirmationPopUpClose}
                            />
                        </ModalChildren>
                    )}
                    <p className={'form-autosize'}>
                        {apn}
                    </p>
                </div>
                <div className="form-group">
                    <label
                        htmlFor="NHDReportCounty"
                        className="capitalize gray-secondary m-0 form-label"
                    >
                        {this.props.defaultFields.county}
                    </label>
                    <p className={'form-autosize'}>
                        {(this.state.location.county) ? this.state.location.county.text : ""}
                    </p>
                </div>
                <Button
                    type="submit"
                    className="btn btn-primary btn-lg btn-block"
                    buttonText={this.props.defaultFields.order_report}
                />
                {this.props.isError && (
                    <span className="error-text">
            <ErrorIcon className="error-icon"/>
            <div className="float-right">
              There has been an error in submitted order. <br/>
                Please try again later.
                </div>
                    </span>
                )}
            </div>
        );
    }
}


export default OrderFormNhdReport;