import React from "react";
import "./modalChildren.css";

const ModalChildren = props => {
  return (
    <div className="modal-root screen">
      <div className={props.isWide ? "modalChildren modalChildren-wide" : "modalChildren"}>
        <div className="close-button-row">
          <button className="close-button">
          </button>
        </div>
        <div className="modalChildren-content">{props.children}</div>
      </div>
    </div>
  );
};

export default ModalChildren;
