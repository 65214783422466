import React from 'react';
import { Text } from '@ftdr/blueprint-components-react';
import classNames from 'classnames';

export interface ContentBoxProps {
    title?: string,
    subtitle?: any,
    style?: any,
    className?: any,
    leftContent?: any,
    leftContentSticky?: boolean,
    contentTitle? : any
}

const ContentBox: React.FC<ContentBoxProps> = (props) => {

    const subtitleClassNames = "mx-auto max-w-xxl mt-4";
    console.log(props.contentTitle);

    return (
        <div className='bds-ahs-web-app'>
            <p className='order-history-content-title'>
                {props.contentTitle}
            </p>
            <div
                className={classNames(['lg:px-6 md:px-6 px-2 lg:py-12 md:py-12 py-7 min-w-80 max-w-xxl mx-auto flex', props.className])}
                style={props.style}
            >
               
                <div className={`float-left h-full mt-17 ${props.leftContentSticky && 'sticky-container-top'}`}>
                    {props.leftContent}
                </div>
                <div className="w-full max-w-xxl">
                    {props.title && (
                        <Text className="mx-auto max-w-xxl" variant="heading-03">{props.title}</Text>
                    )}
                    {props.subtitle && (
                        <Text className={subtitleClassNames} variant="body-short">{props.subtitle}</Text>
                    )}

                    <div className={props.title ? 'mt-8' : ''}>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContentBox;