import React from "react";
import "./Modal.css";

interface modalProps {
  onClose: React.MouseEventHandler;
  onConfirm: React.MouseEventHandler;
  modalBody: any;
  cancelButtonText?: string;
  confirmButtonText?: string;
  className?: string;
}

const Modal: React.FC<modalProps> = props => {
  return (
    <>
      <div className="modal-overlay modal fade show d-block" role="dialog">
        <div
          className={
            props.className ? props.className : "modal-dialog modal-content p-5"
          }
        >
          {props.modalBody}
          {(props.cancelButtonText || props.confirmButtonText) && (
            <div className="text-center modal-footer-buttons">
              <button
                type="button"
                className="btn text-blue"
                data-dismiss="modal"
                onClick={props.onClose}
              >
                {props.cancelButtonText}
              </button>
              <button
                type="button"
                className="btn blue-btn"
                onClick={props.onConfirm}
              >
                {props.confirmButtonText}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Modal;
