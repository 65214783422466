import React from "react";
import "./TaxReport.css";
import FileIcon from "assets/img/file.png";
import {TrackGAEvent} from "../gaTracking/GATracking";
import ConfigHelper from "helpers/ConfigHelper";

interface TaxReportProps {
  report_title: string;
  report_heading_one: string;
  report_price_one: string;
  view_sample: string;
  report_heading_two: string;
  report_price_two: string;
}

const tax_sampleReport = (e: any) => {
  TrackGAEvent("link", "click", "NHD tax and environment sample report");
};

const tax_Report = (e: any) => {
  TrackGAEvent("linl", "click", "NHD tax sample report");
};

const TaxReport: React.FC<TaxReportProps> = ({
  report_title,
  report_heading_one,
  report_price_one,
  view_sample,
  report_heading_two,
  report_price_two
}) => {

  let sampleNHDReportURL = `${ConfigHelper.ZestyBaseMediaUrl}/Sample-NHD-TAX.pdf`;
  let sampleNHDWithENVReportURL = `${ConfigHelper.ZestyBaseMediaUrl}/Sample-NHD-TAX-ENV.pdf`;

  return (
    <div className="report-section">
      <img src={FileIcon} alt="File icon" className="fileIcon" />
      <h6 className="report-title text-center">{report_title}</h6>
      <div className="active">
        {report_heading_one} <b>{report_price_one}</b>
        <a
          className="text-blue float-right"
          href={sampleNHDWithENVReportURL}
          target="_blank"
          onClick={tax_sampleReport}
        >
          {view_sample}
        </a>
      </div>
      <div>
        {report_heading_two} <b>{report_price_two}</b>
        <a
          className="text-blue float-right"
          href={sampleNHDReportURL}
          target="_blank"
          onClick={tax_Report}
        >
          {view_sample}
        </a>
      </div>
    </div>
  );
};

export default TaxReport;
