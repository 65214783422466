import React from "react";
import { LabelField, InputField } from "components";
import { SellerInfoModel, FormFieldModel } from "components/Models";

interface SellerInfoProps {
  defaultFields: SellerInfoModel;
  fieldValues: FormFieldModel;
  isSubmitted: boolean;
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SellerInfo: React.FC<SellerInfoProps> = ({
  defaultFields,
  fieldValues,
  onChangeHandler,
  isSubmitted
}) => {
  return (
    <fieldset>
      <legend className="col-lg-9 col-md-9 col-sm-12 col-xs-12 m-0 p-0">
        <LabelField
          className="form-title"
          labelText={defaultFields.seller_information}
        />
      </legend>
      <InputField
        {...fieldValues.sellerName}
        showOptionalKey={!fieldValues.sellerName.required}
        placeholder={defaultFields.enter_full_name}
        label={defaultFields.seller_name}
        className="form-control form-placeholder"
        isSubmitted={isSubmitted}
        handleChange={onChangeHandler}
        maxLength={100}
        refr={fieldValues.sellerName.refr}
      />

      <InputField
        {...fieldValues.sellerPhone}
        showOptionalKey={!fieldValues.sellerPhone.required}
        showPhoneOrEmailNote={fieldValues.sellerPhone.required && fieldValues.email2.required}
        placeholder={defaultFields.enter_phone_number}
        label={defaultFields.seller_phone}
        className="form-control form-placeholder"
        handleChange={onChangeHandler}
        isSubmitted={isSubmitted}
        maxLength={20}
        refr={fieldValues.sellerPhone.refr}
      />
      <InputField
        {...fieldValues.email2}
        showOptionalKey={!fieldValues.email2.required}
        showPhoneOrEmailNote={fieldValues.sellerPhone.required && fieldValues.email2.required}
        placeholder={defaultFields.enter_email_address}
        label={defaultFields.seller_email}
        className="form-control form-placeholder"
        handleChange={onChangeHandler}
        isSubmitted={isSubmitted}
        maxLength={100}
        refr={fieldValues.email2.refr}
      />
    </fieldset>
  );
};

export default SellerInfo;
