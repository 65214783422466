export default class Address {
  constructor(
    public streetAddress: string = "",
    public city: string = "",
    public state: string  = "",
    public zipCode: string = "",
    public verified: boolean = false,
    public unitNumber: string = "",
  ) {}

  public Equals (otherAddress: Address) : boolean {
    return this.streetAddress.trim().toLowerCase() === otherAddress.streetAddress.trim().toLowerCase() &&
            this.zipCode.trim().toLowerCase() === otherAddress.zipCode.trim().toLowerCase() &&
            this.city.trim().toLowerCase() === otherAddress.city.trim().toLowerCase() &&
            this.state.trim().toLowerCase() === otherAddress.state.trim().toLowerCase();
  }
}
