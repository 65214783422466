import axios, { AxiosResponse } from "axios";
import ConfigHelper from "helpers/ConfigHelper";

const config = {
    headers: {
        'Content-Type': 'application/json',
    }
};

const HeaderImageService = (): Promise<any> => {
    let headerURL = `${ConfigHelper.ZestyBaseUrl}/ajax/header.json`;
    return axios.get(headerURL, config).then((response: AxiosResponse) => {
        let responseData = response.data;
        return responseData;
    }).catch((e) => {
        console.log(e);
        return "";
    });
};

export default HeaderImageService;